<template>
  <div>
    <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
    <TitleCard title="商会商品池" style="max-width: 97%; margin: 10px auto">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">The product pool of business associations</li>
          <!--<li class="breadcrumb-item">-->
          <!--  <span class="bullet bg-gray-200 w-5px h-2px"></span>-->
          <!--</li>-->
          <!--<li class="breadcrumb-item text-muted">源头发货</li>-->
          <!--<li class="breadcrumb-item">-->
          <!--  <span class="bullet bg-gray-200 w-5px h-2px"></span>-->
          <!--</li>-->
          <!--<li class="breadcrumb-item text-muted">合作共赢</li>-->
        </ul>
      </template>
      <div style="position: absolute; right: 70px">
        <a class="el-button el-button--primary mx-2 el-button--mini" href="javascript:void(0)"
           @click="$router.push({ name: 'GroupManage' })">分组管理</a>
        <button :class="idArr.length ? '' : 'is-disabled'" class="el-button el-button--primary el-button--mini"
                type="button" @click="pushAll">
          <i class="el-icon-edit"></i><span> 批量推送</span>
        </button>
      </div>
    </TitleCard>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div style="max-width: 98%; flex-direction: row !important; display: flex" id="kt_content_container"
           class="container">
        <!-- 搜索开始 -->
        <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <!--                <div class="mb-5">
                                  <label class="fs-6 form-label fw-bolder text-dark">协会推荐主题</label>
                                  <div class="position-relative">
                                    <el-badge v-for="(tag,index) in topics" :value="tag.isNew?'新':''"
                                              :key="tag.id" class="custom-badge">
                                      <el-tag
                                          :type="tag.selected ? 'primary' : 'info'"
                                          @mouseover="tagHandleMouseOver(tag)"
                                          @mouseleave="tagHandleMouseLeave(tag)"
                                          @click="handleTagClick(tag)"
                                          class="custom-tag"

                                      >
                                        {{ tag.title }}
                                      </el-tag>
                                    </el-badge>

                                  </div>

                                </div>-->
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品标题</label>
                  <div class="position-relative">
                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                           height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg>
                    </span>
                    <input id="sTitle" type="text" class="form-control form-control-solid ps-10" name="search"
                           v-model="params.sTitle" placeholder="请输入需要查询的商品标题名称" value=""/>
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">企业名称</label>
                  <input id="sPhone" v-model="params.enterpriseName" type="text"
                         class="form-control form-control form-control-solid" placeholder="请输入企业名称"/>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品一级分类</label>
                  <select v-model="queryParam.categoryId1" class="form-select form-select-solid" data-placeholder="全部"
                          @change="changeCategoryId1">
                    <option value="null">全部</option>
                    <option v-for="(item, index) in categoryOne" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品二级分类</label>
                  <select v-model="queryParam.categoryId2" class="form-select form-select-solid"
                          data-placeholder="全部">
                    <option value="null">全部</option>
                    <option v-for="(item, index) in categoryTwo" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <!--                <div class="mb-5">
                                  <label class="fs-6 form-label fw-bolder text-dark">关键词</label>
                                  <input id="sPhone" type="text" v-model="params.phone"
                                         class="form-control form-control form-control-solid" placeholder="请输入关键词"/>
                                </div>-->
                <div class="d-flex align-items-center justify-content-end">
                  <a @click="shaixuan" href="javascript:void(0);" class="btn btn-primary">筛选</a>&nbsp;&nbsp;&nbsp;
                  <a @click="resetQuery" href="javascript:void(0);"
                     class="btn text-hover-primary bg-hover-light btn-color-gray-400 me-3">重置</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->

        <div class="card mb-5 mb-xl-8" style="margin-left: 3px; width: 100%;min-height: 80vh"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body py-3">
            <div class="table-responsive" style="min-height: 60vh">
              <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                     id="kt_ecommerce_products_table">
                <thead>
                <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th aria-label="" class="w-10px pe-2 sorting_disabled" colspan="1" rowspan="1"
                      style="width: 29.8906px">
                    <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input v-model="checkboxInput" class="form-check-input" data-kt-check="true"
                             data-kt-check-target="#kt_ecommerce_products_table .form-check-input" type="checkbox"
                             value="TRUE" @change="checkboxInputEvent"/>
                    </div>
                  </th>
                  <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1"
                      colspan="1" aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                    商品信息
                  </th>
                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="分类" style="width: 80px">
                    分类
                  </th>
                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="市场价" style="width: 80px">
                    市场价
                  </th>
                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="分销价" style="width: 80px">
                    分销价
                  </th>

                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="销售方式"
                      style="width: 80px">
                    销售方式
                  </th>
                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="库存" style="width: 80px"
                      v-if="params.isJd != 1">
                    库存
                  </th>
                  <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="库存" style="width: 80px"
                      v-if="params.isJd != 1">
                    商品来源
                  </th>
                  <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作"
                      style="width: 80px">
                    操作
                  </th>
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr v-for="(item, index) in rows" :class="{ old: index % 2 == 0, even: index % 2 != 0 }" :key="index">
                  <!-- 复选按钮 -->
                  <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input :checked="idArr.includes(item.id)" class="form-check-input" type="checkbox" value="1"
                             @click="addIdArr(item.id)"/>
                    </div>
                  </td>
                  <!-- 商品信息 -->
                  <td>
                    <div class="d-flex align-items-center" @click="openGoodInfo(item.id, item.isJD)">
                      <a href="javascript:void(0);" class="symbol symbol-50px">
                        <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0"
                             :src="`${item.picUrl}`"/>
                        <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${item.picUrl}`"/>
                      </a>
                      <div class="ms-5">
                        <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                           data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pe-0">
                      <span v-html="item.categoryName" v-if="item.categoryName">
                      </span>
                    <span v-else v-html="[
                        item.categoryId1Name,
                        item.categoryId2Name,
                        item.categoryId3Name,
                      ].join('<br>')
                        ">
                      </span>
                  </td>
                  <!-- 市场价 -->
                  <td class="text-center pe-0">
                    <span>{{ item.price | format }} </span>
                  </td>
                  <!-- 分销价 -->
                  <td class="text-center pe-0">
                      <span>{{
                          item.supplPrice | format
                        }}</span>
                  </td>
                  <!-- 利润率 -->
                  <!-- 加价信息 -->
                  <!-- <td class="text-center pe-0">
                    <span class="fw-bold">{{ item.disPriceType }}</span>
                  </td> -->
                  <!-- 销售方式 -->
                  <td class="text-center pe-0">
                    <span>{{ item.buyType }}</span>
                  </td>
                  <!-- 库存 -->
                  <td class="text-center pe-0" v-if="params.isJd != 1">
                    <span>{{ item.stock }}</span>
                  </td>
                  <td class="text-center pe-0" v-if="params.isJd != 1">
                    <el-link type="primary" :underline="false" @click="openVipBaseInfoDialog(item.staffId)">查看</el-link>
                  </td>
                  <!-- 操作 -->
                  <td class="text-center">
                    <!--                    <el-link type="primary" :underline="false" @click="openBuyOneFromGoods(item)">购买</el-link>&nbsp;&nbsp;-->
                    <!--                    <el-link type="primary" :underline="false" @click="openBuyManyFromGoods">集采</el-link>-->
                    <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                       data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="toggleDropdown(index)">
                      操作
                      <i class="ki-outline ki-down fs-5 ms-1"></i>
                    </a>

                    <div :id="'dropdown-' + index"
                         class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                         data-kt-menu="true">

                      <div class="menu-item px-3">
                        <a href="javascript:void(0)" @click="pushGood(item.id, 1)" class="menu-link px-3">挑选上架</a>
                      </div>
                      <div class="menu-item px-3">
                        <a class="menu-link px-3" href="javascript:void(0)"
                           @click="buyGoods(item)">直接购买</a>
                      </div>
                      <div class="menu-item px-3">
                        <a href="javascript:void(0)" class="menu-link px-3" @click="
                            openBuyManyFromGoods(item)
                            ">集采</a>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
          <el-pagination
              :current-page="params.current"
              :page-size="params.size"
              :page-sizes="[10, 20, 50, 300]"
              :total="totalCount"
              layout="total, sizes, prev, pager, next, jumper"
              style="display: flex;justify-content: center;margin-bottom: 20px"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange">
          </el-pagination>
        </div>

      </div>
    </div>
    <!-- 商品详情 -->
    <ModalDialog id="goodInfo" ref="goodInfo" title="商品详情" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>

    <el-dialog :visible.sync="dialogVisible" title="商品信息" width="60%">
      <el-descriptions :column="1" border class="margin-top" title="">
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ item.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-mobile-phone"></i> -->
            市场价
          </template>
          {{ item.price | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-location-outline"></i> -->
            分销价
          </template>
          {{ item.supplPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-tickets"></i> -->
            销售方式
          </template>
          {{ item.buyType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            收件人信息
          </template>
          <el-descriptions :column="5" border direction="vertical" title="">
            <el-descriptions-item label="收件人">{{ addressObj.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ addressObj.tel }}</el-descriptions-item>
            <el-descriptions-item label="收件地址">{{ addressObj.proCity }}{{
                addressObj.address
              }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="操作">
              <el-link type="primary" @click="addAddress"><i
                  class="el-icon-plus el-icon--right"></i>新增
              </el-link>
              <el-link style="margin-left: 10px;" type="primary"
                       @click="moreAddress">更多
              </el-link>
            </el-descriptions-item>
          </el-descriptions>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            购买数量
          </template>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="payGoods">购 买</el-button>
      </span>
    </el-dialog>

    <!-- 修改或新增收货地址 -->
    <el-dialog :close-on-click-modal="false" :title="status == 1 ? '新增收货信息' : '编辑收货信息'"
               :visible.sync="createOrEdit"
               width="30%">
      <el-form :model="editAddressObj">
        <el-form-item label="收货人" style="width:100%">
          <el-input v-model="editAddressObj.name" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" style="margin-bottom:0">
        </el-form-item>
        <el-form :inline="true" label-position="top">
          <el-form-item label="省">
            <el-select v-model="editAddressObj.pro" style="width:120px" @change="proChange">
              <el-option v-for="item in proList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市">
            <el-select v-model="editAddressObj.city" style="width:120px" @change="cityChange">
              <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县(区)">
            <el-select v-model="editAddressObj.area" style="width:120px" @change="areaChange">
              <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道">
            <el-select v-model="editAddressObj.stree" style="width:120px">
              <el-option v-for="item in streeList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="详细地址">
          <el-input v-model="editAddressObj.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editAddressObj.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-checkbox v-model="editAddressObj.isType" false-label="2" true-label="1">设置默认地址</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrEdit = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 收货地址列表 -->
    <el-dialog :visible.sync="addressListDialog" title="收货信息列表" width="50%">
      <el-table :data="addressList">
        <el-table-column label="收件人" property="name"></el-table-column>
        <el-table-column label="收件地址" property="proCity"></el-table-column>
        <el-table-column label="详细地址" property="address"></el-table-column>
        <el-table-column label="手机号" property="tel"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="selectAddress(scope.row)">选择</el-button>
            <el-button size="small" type="text" @click="editAddress(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text"
                       @click="setDefalt(scope.row)">设置默认
            </el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text" @click="delAddress(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressListDialog = false">取 消</el-button>
        <el-button type="primary" @click="addressListDialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 推送 -->
    <ModalDialog id="pushGoodsAll" ref="pushGoodsAll" title="挑选上架" width="850px">
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item :required="true" label="推送目标">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects" @change="changeSelectionObjects">
              <el-checkbox
                  :disabled="pushGoodsObj.selectionObjects.includes('shop') || pushGoodsObj.selectionObjects.includes('topic')"
                  label="group">
                分组
              </el-checkbox>
              <el-checkbox :disabled="pushGoodsObj.selectionObjects.includes('group')" label="shop">商城</el-checkbox>
              <el-checkbox :disabled="pushGoodsObj.selectionObjects.includes('group')" label="topic">活动</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ state ? "1" : idArr.length }} </span> 件商品
          </el-form-item>
          <el-form-item :required="pushGoodsObj.salePriceChange == '1'" label="销售价">
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio :disabled="pushGoodsObj.selectionObjects.includes('group')" label="1">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceReference"
                       :disabled="pushGoodsObj.salePriceChange != 1" placeholder="请选择"
                       size="small" style="width: 150px" @change="selectChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceAdjust" :disabled="pushGoodsObj.salePriceChange != 1"
                       placeholder="请选择"
                       size="small" style="width: 150px">
              <el-option v-for="item in adjustOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-input v-model="pushGoodsObj.salePriceDto.salePriceNum" :disabled="pushGoodsObj.salePriceChange != 1"
                      placeholder="请输入内容"
                      size="small" style="width: 150px"></el-input>
            &nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceUnit" :disabled="pushGoodsObj.salePriceChange != 1"
                       placeholder="请选择"
                       size="small" style="width: 100px">
              <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <br/>
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-button style="float: right; margin-right: 20px" type="primary" @click="nextPush">下一步<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
    </ModalDialog>

    <ModalDialog id="pushGrounp" ref="pushGrounp" :width="pushGoodsObj.selectionObjects.length * 400 + 'px'"
                 title="选择具体推送目标">
      <div style="display: flex">
        <div v-if="pushGoodsObj.selectionObjects.includes('group')"
             style="width: 400px; flex: 1; height: 400px; overflow-y: scroll">
          <h3>分组</h3>
          <div>
            <el-tree ref="groupTree" :current-node-key="groupProps.id" :data="groupProps" node-key="id" show-checkbox
                     @check="groupTreeNodeClick">
            </el-tree>
          </div>
        </div>
        <div v-if="pushGoodsObj.selectionObjects.includes('shop')"
             style="width: 400px; flex: 1; height: 400px; overflow-y: scroll">
          <h3>商城</h3>
          <div>
            <el-tree ref="shopTree" :data="shopProps" node-key="id" show-checkbox @check="shopTreeNodeClick">
            </el-tree>
          </div>
        </div>
        <div v-if="pushGoodsObj.selectionObjects.includes('topic')"
             style="width: 400px; flex: 1; height: 400px; overflow-y: scroll">
          <h3>活动</h3>
          <div>
            <el-tree ref="topicTree" :data="topicProps" node-key="id" show-checkbox @check="topicTreeNodeClick">
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button style="float: right; margin-right: 20px" type="primary" @click="submitPush">提交&nbsp;&nbsp;<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button style="float: right; margin-right: 20px" type="primary" @click="$refs.pushGrounp.close()">上一步<i
          class="el-icon-arrow-left el-icon--left"></i></el-button>
    </ModalDialog>


    <el-dialog :visible.sync="procurementDialogFormVisible" title="集采" width="30%">
      <el-form ref="collectiveProcurementFromRuleForm" :model="collectiveProcurementFrom" :rules="rules">
        <el-form-item label="联系人" label-width="120px" prop="contacts">
          <el-input v-model="collectiveProcurementFrom.contacts" placeholder="请输入联系人" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" label-width="120px" prop="tel">
          <el-input v-model="collectiveProcurementFrom.tel" placeholder="请输入手机号" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="数量" label-width="120px" prop="num">
          <el-input-number v-model="collectiveProcurementFrom.num" :min="1" size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="配送方式" label-width="120px" prop="shippingMethod">
          <el-select v-model="collectiveProcurementFrom.shippingMethod" placeholder="请选择" size="small">
            <el-option :value="1" label="一次性发货"></el-option>
            <el-option :value="2" label="分批发货"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送区域" label-width="120px" prop="address">
          <el-input
              v-model="collectiveProcurementFrom.address"
              :rows="2"
              maxlength="100"
              placeholder="请输入地址"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item label="期望单价" label-width="120px" prop="price">
          <el-input v-model="collectiveProcurementFrom.price" placeholder="请输入期望单价" size="small"
                    @input="changeMessage($event)"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
              v-model="collectiveProcurementFrom.remark"
              :rows="2"
              maxlength="100"
              placeholder="请输入备注"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="procurementDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="vipBaseInfoVisible" title="商品来源" width="80%" @close="closeVipBaseInfoDialog">
      <div class="app-content">
        <div class="card mb-6">
          <div class="card-body pb-0" style="padding-top: 0">
            <div class="d-flex flex-wrap " style="border: 1px solid #F1F1F2;padding: 16px;border-radius: 10px">
              <!--begin: Pic-->
              <div class="me-7 mb-4">
                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img :src="vipInfo.logo" alt="image"/>
                  <div
                      class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1 flex-wrap">
                <!--begin::Title-->
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <!--begin::User-->
                  <div class="d-flex flex-column">
                    <!--begin::Name-->
                    <div class="d-flex align-items-center mb-2">
                      <a href="#"
                         class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{ vipInfo.name }}</a>
                      <a href="#">
                        <i class="ki-outline ki-medal-star fs-1 text-primary" style="font-size:3px">
                          {{ vipInfo.level }}</i>
                      </a>
                    </div>
                    <!--end::Name-->
                    <!--begin::Info-->
                    <div class="d-flex flex-wrap fw-semibold fs-6 pe-2" style="margin-top:5px">
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-profile-circle fs-4 me-1"></i>{{ vipInfo.userName }}</a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-phone fs-4 me-1"></i>{{ vipInfo.tel }}</a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <i class="ki-outline ki-sms fs-4 me-1"></i>{{ vipInfo.email }}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2" style="margin-top:3px">
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-geolocation fs-4 me-1"></i>{{ vipInfo.address }}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2" style="margin-top:-1px">
                      <a href="javascript:void(0)"
                         class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <i class="ki-outline ki-note fs-4 me-1"></i><span
                          class="fs-4">主营品牌：</span>{{ vipInfo.category }}</a>
                    </div>
                    <!--end::Info-->
                    <!--begin::Stats-->
                    <div class="d-flex flex-wrap flex-stack">
                      <!--begin::Wrapper-->
                      <div class="d-flex flex-column flex-grow-1 pe-8">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap" v-if="vipInfo.goodsList">
                          <!--begin::Stat-->
                          <div v-for="(item1,index) in vipInfo.goodsList.list"
                               class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                              <i class="ki-outline ki-verify fs-1 text-primary"></i>
                              <div class="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="236">
                                {{ item1.count }}
                              </div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class=" flex-wrap fw-semibold fs-6 text-gray-400">{{ item1.title }}</div>
                            <!--end::Label-->
                          </div>
                          <!--end::Stat-->


                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Wrapper-->
                      <!--begin::Progress-->

                      <!--end::Progress-->
                    </div>
                    <!--end::Stats-->
                  </div>

                  <!--end::User-->
                  <!--begin::Actions-->
                  <div class="d-flex flex-wrap my-4">
                    <div class="d-flex align-items-center w-600px w-sm-400px flex-column mt-3">
                      <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                        <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                          <i class="ki-outline ki-profile-circle fs-4 me-1"></i>公司介绍</a>
                      </div>
                      <div class="mx-3 w-100 bg-light mb-3">
                        {{ vipInfo.detail }}
                      </div>
                    </div>
                    <!--end::Menu-->
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Title-->

              </div>
              <!--end::Info-->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {mixins} from "@/views/VipGoods/mixins";
import buyMany from "@/components/buyMany/index.vue";
import buyOne from "@/components/buyOne/index.vue";
import vipInfo from "@/components/vipInfo/index.vue";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";

export default {
  name: "VipGoods",
  components: {GoodInfoComponent, buyMany, buyOne, vipInfo},
  mixins: [mixins],


}
</script>
<style>
.custom-tag {
  cursor: pointer;
}

.custom-badge {
  margin-left: 20px;
  margin-bottom: 10px;
}
</style>
