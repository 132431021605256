<template>
  <div>
    <div ref="chartContainer" style="width: 100%;height: 300px;padding-top: 20px"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import {getMonthGoodTen} from "@/api/orderApi";

export default {
  name: "MonthGoods",
  data() {
    return {
      dayArray:[],
      chartOptions:  {
        title: {
          left: 'center',
          text: '本月销量Top10',
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel:{
            formatter: function (params) {
              var str = "";                 // 最终拼接成的字符串
              var paramsLen = params.length;// 获取每项文字的个数
              var len = 6;                  // 每行能显示的字的个数（根据实际情况自己设置）
              var rowNumber = Math.ceil(paramsLen / len); // 换行的话，需要显示几行，向上取整
              if (paramsLen > len) {        //大于设定的len就换行，不大于就不变化
                for (var i = 0; i < rowNumber; i++) {
                  var temp = "";            // 表示每一次截取的字符串
                  var start = i * len;      // 开始截取的位置
                  var end = start + len;    // 结束截取的位置
                  if (i == rowNumber - 1) { // 最后一次不换行
                    temp = params.substring(start, paramsLen);
                  } else {                  // 每一次拼接字符串并换行
                    temp = params.substring(start, end) + "\n";
                  }
                  str += temp;              // 最终拼成的字符串
                }
              } else {                      // 给新的字符串赋值
                str = params;
              }
              return str;                   //返回字符串
            }

          }
        },
        yAxis: {
          type: 'value'
        },
        // tooltip: {
        //   trigger: 'axis'
        // }, // tooltip: {
        //   trigger: 'axis'
        // },
        series: [
          {
            data: [],
            type: 'bar',
          }
        ],
      }
    }
  },
  methods: {
    async getMonthGoodTen(){
      this.dataArr=[];
      this.dataArrNum=[];
      let {data:res}=await getMonthGoodTen();

      let arrTitle=[];
      let arrNum=[];
      res.forEach(e=>{
        let title=e.title;
        if(title.length>18){
          title=title.substr(0,18);
        }
        arrTitle.push(title);
        arrNum.push(e.num)
      })

      this.dataArr=arrTitle;
      this.dataArrNum=arrNum;
    },
    async initChart() {
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);
      await this.getMonthGoodTen();
      this.chartOptions.series[0].data=this.dataArrNum;
      this.chartOptions.xAxis.data=this.dataArr;

      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
  },
  mounted() {

    this.$nextTick(() => {
      this.initChart();
    })
  }
}
</script>


<style scoped>

</style>