<template>
  <div class="card card-flush  h-md-50 mb-5 mb-xl-10" style="display: flex;justify-content: space-around">
    <div class="card-header pt-3">
      <div class="card-title d-flex flex-column">
        <div class="d-flex align-items-center">
          <!--begin::Currency-->
          <span class="fs-4 fw-semibold text-gray-500 me-1 align-self-start">￥</span>
          <!--end::Currency-->

          <!--begin::Amount-->
          <span class="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
<!--            <count-up :endVal="this.totalSalesRevenue"></count-up>-->
             {{this.totalSalesRevenue}}
          </span>
          <!--end::Amount-->

          <!--begin::Badge-->
          <span v-if="this.ratio > 0" class="badge badge-light-success fs-base">
                  <i class="ki-outline ki-arrow-down fs-5 text-success ms-n1"></i>
                  {{ this.ratio }}%
                </span>
          <span v-if="this.ratio < 0" class="badge badge-light-danger fs-base">
                  <i class="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>
                  {{ this.ratio }}%
                </span>
          <!--end::Badge-->
        </div>
        <!--end::Info-->

        <!--begin::Subtitle-->
        <span class="text-gray-500 pt-1 fw-semibold fs-6">
          今日销售额(元)
<!--          <el-tooltip content="仅统计现金付款的销售额" effect="dark" placement="bottom">
            <i class="el-icon-question"/>
          </el-tooltip>-->
        </span>
        <!--end::Subtitle-->
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Card body-->
    <div class="px-0 pb-0" style="margin: 0 auto">
      <div ref="chartContainer" style="width: 250px;height: 100%"></div>
    </div>
    <!--end::Card body-->
  </div>
</template>
<script>
import echarts from "echarts";
import {getTotalSalesRevenueDayApi} from "@/api/orderApi";

export default {
  name: 'dayPrice',
  data() {
    return {
      data: [],
      ratio: 0,
      totalSalesRevenue: 0,
      chartOptions: {
        grid: {
          left: '100px',  // 调整图表容器左侧边距
          containLabel: true,
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: -10,
          top: 20,
          data: ['自有货源'],
          // formatter: function(name) {
          //   // 在图例后面显示对应的数值
          //   var value = echarts.getOption().series[0].data.find(item => item.name === name).value;
          //   return name + ' : ' + value;
          // },
        },
        series: [
          {
            name: '总销售额',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 8,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 0, name: '自有货源'},
            ]
          }
        ]
      }
    }
  },
  methods: {
    async initChart() {
      await this.getTotalSalesRevenueDay();
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);

      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
    async getTotalSalesRevenueDay() {
      let {data} = await getTotalSalesRevenueDayApi()
      this.chartOptions.series[0].data[0].value = data.oneselfTotalSalesRevenue;
      this.totalSalesRevenue = data.totalSalesRevenue;
      let tempRatio = data.ratio * 100;
      if (data.ratio < 0) {
        tempRatio = tempRatio * -1;
      }
      this.ratio = tempRatio;
    }
  },
  mounted() {
    this.initChart();
  }
}
</script>
