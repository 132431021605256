<template>
  <div>
    <div id="productModalBody" class="modal-body">
      <!-- 内容开始 -->
      <div id="kt_post" class="post d-flex flex-column-fluid">
        <!--begin::Container-->
        <div id="kt_content_container" class="container"
             style="padding:1px 1px 1px 1px;max-width:100%;flex-direction:row!important;display:flex;">
          <!-- 搜索开始 -->
          <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-300px ">
            <!--begin::Form-->
            <form action="#">
              <!--begin::Card-->
              <div class="card">
                <!--begin::Body-->
                <div class="card-body">
                  <!--begin:Search-->
                  <div class="position-relative">
                    <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                      <svg height="24px" version="1.1" viewBox="0 0 24 24"
                           width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                          <rect height="24" width="24" x="0" y="0"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <input v-model="goodsQueryParams.title" class="form-control form-control-solid ps-10" name="title"
                           placeholder="请输入需要查询的商品名称"
                           type="text" value="">
                  </div>
                  <!--end:Search-->
                  <!--begin::Border-->
                  <div class="separator separator-dashed my-8"></div>
                  <!--end::Border-->
                  <!--begin::Input group-->
                  <div class="mb-5">
                    <label class="fs-6 form-label fw-bolder text-dark">一级分类</label>
                    <!--begin::Select-->
                    <select id="adfada" v-model="goodsQueryParams.categoryId1" class="form-select form-select-solid"
                            data-placeholder="全部" @change="changeCategoryId1">
                      <option value="null">全部</option>
                      <option v-for="(item, index) in categoryOne" :key="index" :value="item.categoryId">
                        {{ item.fullName }}
                      </option>
                    </select>

                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="mb-5">
                    <label class="fs-6 form-label fw-bolder text-dark">二级分类</label>
                    <!--begin::Select-->
                    <!--begin::Select-->
                    <select id="adfada2" v-model="goodsQueryParams.categoryId2" class="form-select form-select-solid"
                            data-placeholder="全部">
                      <option value="null">全部</option>
                      <option v-for="(item, index) in categoryTwo" :key="index" :value="item.categoryId">
                        {{ item.fullName }}
                      </option>
                    </select>

                    <!--end::Select-->
                  </div>


                  <!--begin::Action-->
                  <div class="d-flex align-items-center justify-content-end">
                    <a class="btn btn-primary" @click="findProduct">筛选</a>&nbsp;&nbsp;&nbsp; <a
                      class="btn btn-active-light-primary btn-color-gray-400 me-3"
                      @click="resetQuery">重置</a>
                  </div>
                  <!--end::Action-->
                </div>
                <!--end::Body-->
              </div>
              <!--end::Card-->
            </form>
            <!--end::Form-->
          </div>
          <!-- 列表开始 -->
          <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">活动商品列表</span> <span
                  class="text-muted mt-1 fw-bold fs-7">挑选合适的商品展示</span>
              </h3>
              <div class="d-flex flex-wrap my-1">
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-6 mb-2 mb-sm-0">
                  <!-- 自营订单 -->
                  <el-button :disabled="batchRemoveTopicBtnDisabled" size="small" style="background-color: #f46789;height:30px"
                             type="danger" @click="batchRemoveGoodsFromTopic">批量移除
                  </el-button>
                  <!-- 分销订单 -->
                </ul>
                <!--end::Tab nav-->
                <!--begin::Actions-->
                <div style="margin-right: 20px">
                  <!--begin::Select-->
                  <!--end::Select-->
                </div>
                <!--end::Actions-->

              </div>

            </div>
            <!--end::Header-->
            <!--begin::Body-->

            <div class="tab-content">
              <!--begin::Tab pane-->
              <div id="kt_project_users_card_pane2" class="tab-pane fade active show">
                <!--begin::Card-->
                <div class="card card-flush">
                  <!--begin::Card body-->
                  <div class="card-body pt-0">
                    <!--begin::Table container-->
                    <div class="table-responsive tableProduct">
                      <!--begin::Table-->
                      <el-table id="tableProduct" ref="topicGoodsTable" :data="topicGoodsList"
                                empty-text="未查询到数据，请前往全国商品池或者分组挑选商品"
                                @selection-change="handleSelectionChange">
                        <template #empty>
                          未查询到数据，请前往<a>
                          <router-link to="/goods">商品中</router-link>
                        </a>添加数据。
                        </template>
                        <el-table-column
                            type="selection" width="50">
                        </el-table-column>
                        <el-table-column
                            label="商品信息"
                            width="300"
                        >
                          <template slot-scope="scope">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-50px me-5"><img :src="scope.row.picUrl"
                                                                        alt="" class=""></div>
                              <div class="d-flex justify-content-start flex-column cursor-pointer"><a
                                  class="text-muted d-block fs-7"
                                  @click="openGoodInfo(scope.row.skuId,scope.row.place)">{{ scope.row.title }} </a>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="分类"
                            width="80"
                        >
                          <template slot-scope="scope">
                            <span class="text-muted d-block fs-7">{{
                                scope.row.categoryId1Name
                              }}<br>{{ scope.row.categoryId2Name }}</span>
                          </template>
                        </el-table-column>

                        <el-table-column
                            label="市场价"
                            width="80"
                        >
                          <template slot-scope="scope">
                            <a class="text-dark fw-bolder d-block mb-1 fs-7">¥{{ scope.row.price }}</a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="普通会员销售价"
                            width="120"
                        >
                          <template slot-scope="scope">
                            <a class="text-dark fw-bolder d-block mb-1 fs-7">¥{{ scope.row.topicSalePrice }}</a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="银牌会员销售价"
                            width="120"
                        >
                          <template slot-scope="scope">
                            <a class="text-dark fw-bolder d-block mb-1 fs-7">¥{{ scope.row.topicSilverPrice }}</a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="金牌会员销售价"
                            width="120"
                        >
                          <template slot-scope="scope">
                            <a class="text-dark fw-bolder d-block mb-1 fs-7">¥{{ scope.row.topicGoldPrice }}</a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="销售方式"
                        >
                          <template slot-scope="scope">
                            <a class="text-muted  d-block fs-7">{{ scope.row.buyTypeStr }}</a>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                        >
                          <template slot-scope="scope">
                            <!--                            <a  class="btn btn-success   btn-sm px-4" data-bs-toggle="modal"
                                                                                          data-bs-target="#editProduct2ShopModal"
                                                                                          @click="updateAddPrice(scope.$index)">改价</a>&nbsp;&nbsp;-->
                            <a class="btn btn-danger   btn-sm px-4"
                               @click="removeProductFromShop(scope.$index)">移除</a>&nbsp;&nbsp;

                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!--end::Table container-->
                    <!-- 分页按钮 -->
                    <el-pagination :current-page="goodsQueryParams.pageIndex"
                                   :page-size="goodsQueryParams.pageSize"
                                   :page-sizes="[10,20,30,50,100]" :total="totalCount"
                                   background
                                   layout="total, sizes, prev, pager, next, jumper"
                                   style="display:flex;justify-content:flex-end;margin-top: 5px" @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange">
                    </el-pagination>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Card-->
            </div>
            <!--end::Tab pane-->
            <!-- 弹出框 -->
          </div>
          <!--begin::Body-->
        </div>
      </div>
      <!--end::Container-->
    </div>
    <el-dialog v-if="updateAddPriceVisible" :visible.sync="updateAddPriceVisible" appendToBody=true>
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2>改价</h2>
          </div>
        </div>
      </template>
      <div>
        <el-form ref="updateAddPrice" :model="updateAddPrice" label-width="80px">
          <el-form-item label="商品名：" label-width="100px">
            <span style="color: black">{{ updateAddPriceObj.title }} </span>
          </el-form-item>
          <el-form-item
              label="销售价："
              label-width="100px"
          >
            按照
            &nbsp;&nbsp;&nbsp;
            <el-select
                v-model="updateAddPriceObj.fieldType"
                placeholder="请选择"
                size="small"
                style="width: 100px"
                @change="caluclateCurrentSalePrice"
            >
              <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-select
                v-model="updateAddPriceObj.isPlus"
                placeholder="请选择"
                size="small"
                style="width: 100px"
                @change="caluclateCurrentSalePrice"
            >
              <el-option v-if="updateAddPriceObj.fieldType==2"
                         key="0"
                         :value="2"
                         label="下调"
              >

              </el-option>
              <el-option v-for="(item,index) in adjustOptions"
                         v-else
                         :key="index"
                         :label="item.label"
                         :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-input
                v-model="updateAddPriceObj.disPrice"
                min="0"
                placeholder="请输入内容"
                size="small"
                style="width: 100px"
                type="number"
                @blur="caluclateCurrentSalePrice"
            ></el-input>
            &nbsp;
            <el-select
                v-model="updateAddPriceObj.disPriceType"
                placeholder="请选择"
                size="small"
                style="width: 100px"
                @change="caluclateCurrentSalePrice"
            >
              <el-option
                  v-for="(item,index) in unitOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商城销售价：" label-width="100px">
            <span style="color: red">{{ updateAddPriceObj.topicSalePrice }} </span>
          </el-form-item>
          <el-form-item label="分销价：" label-width="100px">
            <span>{{ updateAddPriceObj.supplyPrice }} </span>
          </el-form-item>
          <el-form-item label="市场价：" label-width="100px">
            <span>{{ updateAddPriceObj.price }} </span>
          </el-form-item>
        </el-form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button"
                @click="closeUpdateAddPrice">关闭
        </button>

        <button class="btn btn-primary" style="margin-left: 20px"
                type="button" @click="submitUpdateAddPrice">提交
        </button>
      </div>

    </el-dialog>

    <el-dialog v-if="goodsDetailVisible" :visible.sync="goodsDetailVisible" appendToBody=true>
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2>商品详情</h2>
          </div>
        </div>
      </template>
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </el-dialog>
  </div>
</template>
<script>

import {getCategoryList} from '@/api/categoryApi';
import {
  batchDeleteByIds,
  calculateTopicSalePrice,
  deleteById,
  getTopicGoodGroupList,
  getTopicGoodsCount,
  getTopicGoodsList,
  updateAddPrice
} from '@/api/shopTopicGoodsApi';

import Pagination from "@/components/Pagination";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";


export default {
  components: {
    Pagination,
    GoodInfoComponent
  },
  props: {
    currentTopicId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      updateAddPriceVisible: false,
      goodsDetailVisible: false,
      batchRemoveTopicBtnDisabled: true,
      updateAddPriceObj: {
        title: "dawdadasf",
        disPrice: 0,
        disPriceType: 1,
        fieldType: 1,
        isPlus: 1,
        topicSalePrice: 0,
        supplyPrice: 0,
        place: 1,
        relationId: -1,
        price: 0
      },
      unitOptions: [
        {
          value: 1,
          label: "%",
        },
        {
          value: 2,
          label: "元",
        },
      ],
      adjustOptions: [
        {
          value: 1,
          label: "上调",
        },
        {
          value: 2,
          label: "下降",
        },
      ],
      options: [
        {
          value: 1,
          label: "销售价",
        },
        {
          value: 2,
          label: "市场价",
        },
        {
          value: 3,
          label: "分销价",
        },
      ],
      categoryOne: [],
      categoryTwo: [],
      shopGoodsGroupList: [],
      loading: false,
      viewType: 1,
      goodsQueryParams: {
        place: 2,
        pageIndex: 1,
        pageSize: 10,
        title: "",
        supplyTel: "",
        minSupplyPrice: null,
        maxSupplyPrice: null,
        categoryId1: null,
        categoryId2: null,
        groupId: null,
        topicId: this.currentTopicId,
      },
      topicGoodsList: [],
      totalCount: 0,
      multipleSelection: [],
      userRole: localStorage.getItem('role')
    };
  },
  methods: {
    //重置
    resetQuery() {
      this.goodsQueryParams = {
        place: 2,
        pageIndex: 1,
        pageSize: 10,
        title: "",
        supplyTel: "",
        minSupplyPrice: null,
        maxSupplyPrice: null,
        categoryId1: null,
        categoryId2: null,
        groupId: null,
        topicId: this.currentTopicId
      };
      this.getChooseGoodsList()
    },
    findProduct() {
      this.goodsQueryParams.pageIndex = 1
      this.getChooseGoodsList();
    },
    async getChooseGoodsList() {
      if (this.goodsQueryParams.categoryId1 == "null") {
        this.goodsQueryParams.categoryId1 = null;
      }
      if (this.goodsQueryParams.categoryId2 == "null") {
        this.goodsQueryParams.categoryId2 = null;
      }
      if (this.goodsQueryParams.groupId == "null") {
        this.goodsQueryParams.groupId = null;
      }
      if (this.goodsQueryParams.place == "null") {
        this.goodsQueryParams.place = null;
      }
      let {data} = await getTopicGoodsList(this.goodsQueryParams);
      this.topicGoodsList = data;
      if (this.goodsQueryParams.pageIndex == 1) {
        const res = await getTopicGoodsCount(this.goodsQueryParams);
        this.totalCount = res.data;
      }
      this.loading = false;
    },

    handleCurrentChange(param) {
      this.goodsQueryParams.pageIndex = param
      this.getChooseGoodsList();
    },
    handleSizeChange(param) {
      this.goodsQueryParams.pageIndex = 1
      this.goodsQueryParams.pageSize = param
      this.getChooseGoodsList();
    },
    async getPageCount() {
      // let { data } = await getChooseGoodsListCountApi(this.params);
      // this.totalCount = data;
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    changIsJd(index) {
      this.first = true;
      this.params.isJd = index;
      this.getChooseGoodsList();
    },
    //初始化分类
    async getCategory1List(parentId) {
      const {data} = await getCategoryList({parentId});
      this.categoryOne = data.list
    },
    async changeCategoryId1() {
      const categoryId1 = this.goodsQueryParams.categoryId1;
      console.log(categoryId1)
      this.goodsQueryParams.categoryId2 = null
      if (categoryId1 == "null") {
        this.categoryTwo = []
      } else {
        const param = {parentId: categoryId1};
        const {data} = await getCategoryList(param);
        this.categoryTwo = data.list
        console.log(data)
      }
    },
    viewMode(type) {
      this.viewType = type
      this.goodsQueryParams.pageIndex = 1
    },
    async getWxTopicGoodGroupList() {
      let queryParam = {topicId: this.currentTopicId}
      const {data} = await getTopicGoodGroupList(queryParam)
      this.shopGoodsGroupList = data
    },
    updateAddPrice(index) {
      const obj = this.topicGoodsList[index];
      console.log(obj)
      this.updateAddPriceObj.title = obj.title
      if (obj.fieldType == null || obj.fieldType == 0) {
        this.updateAddPriceObj.isPlus = 1
        this.updateAddPriceObj.fieldType = 3
      } else if (obj.fieldType == 2) {
        this.updateAddPriceObj.fieldType = obj.fieldType
        this.updateAddPriceObj.isPlus = 2
      } else {
        this.updateAddPriceObj.isPlus = obj.isPlus
        this.updateAddPriceObj.fieldType = obj.fieldType
      }
      this.updateAddPriceObj.disPrice = obj.topicDisPrice
      this.updateAddPriceObj.disPriceType = obj.topicDisPriceType
      this.updateAddPriceVisible = true
      this.updateAddPriceObj.relationId = obj.relationId
      this.updateAddPriceObj.topicSalePrice = obj.topicSalePrice
      this.updateAddPriceObj.supplyPrice = obj.supplyPrice
      this.updateAddPriceObj.place = obj.place
      this.updateAddPriceObj.price = obj.price
    },
    async submitUpdateAddPrice() {
      const that = this
      if (this.updateAddPriceObj.topicSalePrice > this.updateAddPriceObj.price) {
        this.$message({
          message: "商城销售价不能超过市场价",
          type: "error"
        })
        return
      }
      if (this.updateAddPriceObj.topicSalePrice < this.updateAddPriceObj.supplyPrice) {
        this.$message({
          message: "商城销售价不能低于商品分销价",
          type: "error"
        })
        return
      }
      const {data} = await updateAddPrice(this.updateAddPriceObj);
      if (data) {
        this.$message({
          message: "更新价格成功",
          type: "success"
        })
        await that.getChooseGoodsList()
        this.updateAddPriceVisible = false
      } else {
        this.$message({
          message: "更新价格失败",
          type: "success"
        })
      }
    },
    closeUpdateAddPrice() {
      this.updateAddPriceVisible = false;

    },
    async caluclateCurrentSalePrice() {
      if (this.updateAddPriceObj.disPrice < 0) {
        this.updateAddPriceObj.disPrice = 0
        this.$message({
          type: 'info',
          message: '加价数值不能为负数,已重置为0!'
        });
      }
      if (this.updateAddPriceObj.disPriceType == 1) {
        if (this.updateAddPriceObj.disPrice >= 100) {
          this.updateAddPriceObj.disPrice = 99.99
          this.$message({
            type: 'info',
            message: '按比例加价时,加价不能超过100%,已重置为99.99!'
          });
        }
      }
      const {data} = await calculateTopicSalePrice(this.updateAddPriceObj)
      console.log(data)
      this.updateAddPriceObj.topicSalePrice = data
    },
    removeProductFromShop(index) {
      const that = this;
      this.$confirm('是否下架该商品?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'warning'
      }).then(async () => {
        const obj = this.topicGoodsList[index];
        const deleteParam = {
          relationId: obj.relationId
        }
        const {data} = await deleteById(deleteParam)
        if (data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          await that.getChooseGoodsList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.batchRemoveTopicBtnDisabled = false
      } else {
        this.batchRemoveTopicBtnDisabled = true
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.topicGoodsTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.topicGoodsTable.clearSelection();
      }
    },
    batchRemoveGoodsFromTopic() {
      const that = this;
      this.$confirm('是否下架所选商品?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'warning'
      }).then(async () => {
        let relationIds = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          relationIds.push(this.multipleSelection[i].relationId)
        }


        const {data} = await batchDeleteByIds(relationIds)
        if (data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          await that.getChooseGoodsList()
          this.multipleSelection = []
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    openGoodInfo(id, isJd) {
      this.goodsDetailVisible = true
      //this.$refs.goodInfo.open()
      setTimeout(() => {
        this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
      }, 200)
    },


  },
  created() {
    this.getChooseGoodsList();
    this.getCategory1List(0);
    //this.getWxTopicGoodGroupList();
  },
  mounted() {
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件

  },
};
</script>

<style scoped>
/* 修改滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 10px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #999; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设置滚动条滑块圆角 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}
</style>
