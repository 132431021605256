import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login"
import MainLayout from "@/views/MainLayout";
import Register from "@/views/Register";
import Dashboard from "@/views/Dashboard"
import ChooseGoods from "@/views/ChooseGoods"
import Goods from "@/views/Goods"
import GoodsManage from "@/views/Goods/components/GoodsManage";
import AddOrUpdateInstall from "@/views/Goods/components/Install/AddOrUpdateInstall.vue";
import AddOrUpdateDelivery from "@/views/Goods/components/Delivery/AddOrUpdateDelivery.vue";
import AddOrUpdateFreight from "@/views/Goods/components/Freight/AddOrUpdateFreight.vue";
import Freight from "@/views/Goods/components/Freight/FreightList.vue";
import Permission from "@/views/Permission";
import GroupManage from "@/views/GroupManage";
import GroupDetail from "@/views/GroupManage/components/GroupDetail/index.vue";
import Finance from "@/views/Finance"
import Demo from "@/views/Demo"
import Order from "@/views/Order"
import store from '@/store';
import eCardNew from '@/views/eCardNew/index.vue'
import Suppliers from '@/views/Suppliers/index.vue'
import VipGoods from "@/views/VipGoods/index.vue";
import VipUser from "@/views/VipUser/index.vue";
import vipCollectiveProcurementOrder from "@/views/vipCollectiveProcurementOrder/index.vue"
import PayCallback from "@/views/PayCallback/index.vue";
import CustomizationShop from "@/views/CustomizationShop/index.vue";
import BannerTopic from "@/views/BannerTopic"

Vue.use(VueRouter)

let permissionRouter = [
    {
        path: '/',
        component: Dashboard,
        title: "首页",
        name: "Dashboard",
        subTitle: "首页",
        icon: "ki-graph-3",
        role: ["user:login"]
    },
    {
        path: '/goods',
        component: Goods,
        title: "商品",
        subTitle: "商品",
        icon: "ki-purchase",
        role: ["goods:manage:sjGoods:query:list"],
        children: [
            {
                path: '',
                component: GoodsManage,
                title: "商品管理",
                subTitle: "商品管理",
                icon: "ki-purchase",
                role: ["goods:manage:sjGoods:query:list"],
            },{
                path: '',
                title: "模板管理",
                component:Goods,
                subTitle: "模板管理",
                icon: "ki-purchase",
                role: ["goods:manage:freight:query:list"],
                children:[
                    {
                        path: 'freight',
                        component: Freight,
                        title: "运费模板",
                        subTitle: "运费模板",
                        icon: "ki-dollar",
                        role: ["goods:manage:freight:query:list"],
                    }/*, {
                        path: 'delivery',
                        component: Delivery,
                        title: "送货上门",
                        subTitle: "送货上门",
                        icon: "ki-dollar",
                        role: ["goods:manage:install:query:list"],
                    }, {
                        path: 'install',
                        component: Install,
                        title: "上门安装",
                        subTitle: "上门安装",
                        icon: "ki-dollar",
                        role: ["goods:manage:delivery:query:list"],
                    }*/
                ]
            }
        ]
    },
    {
        path: '/vip',
        component: VipUser,
        title: "会员天地",
        subTitle: "会员天地",
        icon: "ki-duotone ki-badge",
        role: ["association:show"],
        children: [
            {
                path: '',
                component: VipUser,
                title: "会员天地",
                subTitle: "会员天地",
                icon: "ki-duotone ki-badge",
                role: ["association:show"],
            }
        ]
    },
    {
        path: '/vipGoods',
        component: VipGoods,
        title: "商会商品池",
        subTitle: "商会商品池",
        icon: "ki-duotone ki-abstract-21",
        role: ["association:show"],
        children: [
            {
                path: '',
                component: VipGoods,
                title: "商会商品池",
                subTitle: "商会商品池",
                icon: "ki-duotone ki-abstract-21",
                role: ["association:show"],
            }
        ]
    },
    {
        path: '/chooseGoods',
        component: ChooseGoods,
        title: "全国商品池",
        subTitle: "全国商品池",
        icon: "ki-duotone ki-abstract-35",
        role: ["pro:pool:choose:goods:query:list:new"],
        children: [
            {
                path: '',
                component: ChooseGoods,
                title: "全国商品池",
                subTitle: "全国商品池",
                icon: "ki-duotone ki-abstract-35",
                role: ["pro:pool:choose:goods:query:list:new"],
            }
        ]
    },
    {
        path: '/shop',
        component: CustomizationShop,
        title: "商城",
        subTitle: "商城",
        icon: "ki-shop",
        role: ["finance:order:sj:query:list"],
        children: [
            {
                path: '',
                component: CustomizationShop,
                title: "商城",
                subTitle: "商城",
                icon: "ki-shop",
                role: ["finance:order:sj:query:list"],
            }
        ]

    },
    // {
    //     path: '/eCard',
    //     component: eCard,
    //     title: "数字卡券",
    //     subTitle: "卡券",
    //     icon: "ki-graph-3",
    //     role: ["user:login"],
    //     children: [
    //         {
    //             path: '',
    //             component: ChooseGoods,
    //             title: "数字卡券",
    //             subTitle: "卡券",
    //             icon: "ki-graph-3",
    //             role: ["user:login"],
    //         }
    //     ]
    //
    // },
    {
        path: '/eCardNew',
        component: eCardNew,
        title: "数字卡券",
        subTitle: "卡券",
        icon: "ki-graph-3",
        role: ["goods:manage:goods:e:card:combo:gift:voucher:list"],
        children: [
            {
                path: '',
                component: eCardNew,
                title: "数字卡券",
                subTitle: "卡券",
                icon: "ki-graph-3",
                role: ["goods:manage:goods:e:card:combo:gift:voucher:list"],
            }
        ]

    },
    {
        path: '/order',
        component: Order,
        title: "订单",
        subTitle: "订单",
        icon: "ki-delivery-3",
        role: ["finance:order:sj:query:list"],
        children: [
            {
                path: '',
                component: Order,
                name: "Order",
                title: "订单",
                subTitle: "订单",
                icon: "ki-delivery-3",
                role: ["finance:order:sj:query:list"],
            }
        ]
    },
    {
        path: '/bannerTopic',
        component: BannerTopic,
        title: "轮播/活动",
        subTitle: "轮播/活动",
        icon: "ki-graph-3",
        role: ["finance:order:sj:query:list"],
        children: [
            {
                path: '',
                component: BannerTopic,
                name: "BannerTopic",
                title: "轮播/活动",
                subTitle: "轮播/活动",
                icon: "ki-graph-3",
                role: ["finance:order:sj:query:list"],
            }
        ]
    },
    /* {
         path: '/withdrawalApplication',
         component: WithdrawalApplication,
         title: "提现",
         subTitle: "提现",
         icon: "el-icon-money",
         role: ["finance:order:sj:query:list"],
         children: [
             {
                 path: '',
                 component: WithdrawalApplication,
                 name: "withdrawalApplication",
                 title: "提现",
                 subTitle: "提现",
                 icon: "el-icon-money",
                 role: ["finance:order:sj:query:list"],
             }
         ]
     },*/
    {
        path: '/vipCollectiveProcurementOrder',
        component: vipCollectiveProcurementOrder,
        title: "集采订单",
        subTitle: "集采订单",
        icon: "ki-delivery-3",
        role: ["vip:collective:procurement:list"],
        children: [
            {
                path: '',
                component: vipCollectiveProcurementOrder,
                name: "vipCollectiveProcurementOrder",
                title: "集采订单",
                subTitle: "集采订单",
                icon: "ki-delivery-3",
                role: ["vip:collective:procurement:list"],
            }
        ]
    },
    {
        path: '/finance',
        component: Finance,
        title: "财务",
        subTitle: "财务",
        icon: "ki-tag",
        role: ["finance:sjCashOut:query:list:my:money"],
        redirct: "",
        children: [
            {
                path: '',
                component: Finance,
                title: "财务管理",
                subTitle: "财务管理",
                icon: "ki-tag",
                role: ["finance:sjCashOut:query:list:my:money"],
            }
        ]
    },
    {
        path: '/Suppliers',
        component: Suppliers,
        title: "供应商",
        subTitle: "供应商",
        icon: "ki-profile-user",
        role: ["user:permission:create:distributor"],
        redirct: "",
        children: [
            {
                path: '',
                component: Permission,
                title: "供应商管理",
                subTitle: "供应商管理",
                icon: "ki-tag",
                role: ["user:permission:create:distributor"],
            }
        ]
    },
    {
        path: '/permission',
        component: Permission,
        title: "用户权限",
        subTitle: "用户权限",
        icon: "ki-user",
        role: ["user:permission:update"],
        redirct: "",
        children: [
            {
                path: '',
                component: Permission,
                title: "用户权限管理",
                subTitle: "用户权限管理",
                icon: "ki-tag",
                role: ["user:permission:update"],
            }
        ]
    }
]
store.state.permission.routes = permissionRouter

const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            ...permissionRouter
        ]
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/demo',
        component: Demo,
    },
    {
        path: '/payCallback',
        component: PayCallback,
    },
    // {
    //     // path: '/register',
    //     component: Register,
    // }
    // ,
    {
        path: '/register',
        component: Register,
    }
    // , {
    //     path: '/goods',
    //     component: MainLayout,
    //     title: "商品",
    //     subTitle: "商品",
    //     icon: "ki-purchase",
    //     role: ["user:login"],
    //     children: [
    //         {
    //             path: 'freight',
    //             component: Freight,
    //             title: "运费模板",
    //             subTitle: "运费模板",
    //             icon: "ki-dollar",
    //             role: ["user:login"],
    //         }, {
    //             path: 'delivery',
    //             component: Delivery,
    //             title: "送货上门",
    //             subTitle: "提现管理",
    //             icon: "ki-dollar",
    //             role: ["user:login"],
    //         }, {
    //             path: 'install',
    //             component: Install,
    //             title: "上门安装",
    //             subTitle: "上门安装",
    //             icon: "ki-dollar",
    //             role: ["user:login"],
    //         }
    //     ]
    // }
    ,
    // {
    //     path: '/goodsManage',
    //     component: MainLayout,
    //     children: [
    //         {
    //             path: 'addOrUpdateGoods/:id/:status?',
    //             component: AddOrUpdateVisible,
    //             name: "AddOrUpdateVisible",
    //             title: "商品管理",
    //             subTitle: "商品管理",
    //             icon: "ki-purchase",
    //             role: ["user:login"],
    //         }
    //     ]
    // },
    {
        path: '/install',
        component: MainLayout,
        children: [
            {
                path: 'addOrUpdateInstall/:id?',
                component: AddOrUpdateInstall,
                name: "AddOrUpdateInstall",
                title: "上门安装模板",
                subTitle: "上门安装模板",
                icon: "ki-dollar",
                role: ["user:login"],
            }
        ]
    },
    {
        path: '/goods',
        component: MainLayout,
        role: ["user:login"],
        children: [
            {
                path: 'goods_group',
                component: GroupManage,
                name: "GroupManage",
                title: "分组管理",
                subTitle: "分组管理",
                role: ["user:login"],
            },
            {
                path: 'groupDetail/:id?',
                component: GroupDetail,
                name: "GroupDetail",
                title: "分组详情",
                subTitle: "分组管理",
                icon: "ki-purchase",
                role: ["user:login"],
            }
        ]
    },
    {
        path: '/delivery',
        component: MainLayout,
        children: [
            {
                path: 'addOrUpdateDelivery/:id?',
                component: AddOrUpdateDelivery,
                name: "AddOrUpdateDelivery",
                title: "送货上门模板",
                subTitle: "送货上门模板",
                icon: "ki-dollar",
                role: ["user:login"],
            }
        ]
    },
    {
        path: '/freight',
        component: MainLayout,
        children: [
            {
                path: 'addOrUpdateFreight/:id?',
                component: AddOrUpdateFreight,
                name: "AddOrUpdateFreight",
                title: "运费模板",
                subTitle: "运费模板",
                icon: "ki-dollar",
                role: ["user:login"],
            }
        ]
    },

    {
        path: '/',
        component: MainLayout,
        children: [
            ...permissionRouter
        ]
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/register',
        component: Register,
    },
    {
        path: '/goodsManage',
        component: MainLayout,
        children: [
            {
                path: 'addOrUpdateGoods/:id?',
                component: () => import("@/views/Goods/components/GoodsManage/components/AddOrUpdateVisible/AddOrUpdateGoods.vue"),
                name: "AddOrUpdateVisible",
                title: "商品管理",
                subTitle: "商品管理",
                icon: "ki-purchase",
                role: ["user:login"],
            }
        ]
    },
    {
        path: '/messages',
        component: MainLayout,
        children: [
            {
                path: '',
                component: () => import("@/views/AllMessages"),
                name: "AllMessages",
                title: "消息管理",
                subTitle: "消息管理",
            }
        ]
    },
    // {
    //     path: '/comboGiftPack',
    //     component: MainLayout,
    //     role: ["user:login"],
    //     children: [
    //         {
    //             path: '',
    //             component: () => import("@/views/ComboGiftPack"),
    //             name: "comboGiftPack",
    //             title: "组合礼包",
    //             subTitle: "组合礼包",
    //             role: ["user:login"],
    //         }
    //     ]
    // },
     {
         path: '/comboGiftPack',
         component: MainLayout,
         children: [
             {
                 path: 'addOrUpdateComboGiftPack/:id?',
                 component: () => import("@/views/ComboGiftPack/components/AddComboGiftPack/index.vue"),
                 name: "AddOrUpdateComboGiftPack",
                 title: "组合礼包",
                 //subTitle: "组合礼包",
                 icon: "ki-purchase",
                 role: ["user:login"],
             }
         ]
     },
]
console.log(routes)
store.state.permission.tempRoutes = routes
const router = new VueRouter({
    // mode: 'history',
    routes
})

export default router
