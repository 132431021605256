<!-- 组件模板内容 -->
<template>
  <div>

    <el-upload v-if="imageUrl==''"
               :http-request="handleChange"
               :show-file-list="false"
               accept="image/*"
               action="#"
               class="avatar-uploader"
    >
      <div class="customerUploadBase">
        <i class="el-icon-plus"></i>
      </div>

    </el-upload>
    <div v-else class="customerUploadBase">
      <div class="imgContain">
        <img :src="imageUrl"/>
        <i class="el-icon-delete " @click="handleRemove"></i>
      </div>

    </div>
  </div>
</template>

<script>
import {uploadFileApi} from "@/api/uploadFileApi";

export default {
  name: "UploadFile",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    moduleName: {
      type: String
    },
    imgUrl: {
      type: String,
    }
  },
  // 组件的data属性
  data() {
    return {
      imageUrl: this.imgUrl
    };
  },
  computed: {},
  watch: {
    imgUrl(newVal, oldVal) {
      this.imageUrl = newVal
    }
  },
  // 组件的方法
  methods: {
    async handleChange(event) {
      console.log(event)
      const data = new FormData();
      if (event) {
        const file = event.file
        data.append("file", file);
        data.append("moduleName", this.moduleName);
      }
      let res = await uploadFileApi(data);
      this.imageUrl = res.data.fileUrl
      this.$message({
        message: "上传成功!",
        type: "success",
      });

      this.$emit("upload-success", res)
    },
    handleRemove() {
      this.imageUrl = ""
      this.$emit("cancel-success", true);

    },

    uploadSuccess(res) {
      console.log(res);
    },
    cancelSuccess(res) {

    }
  },
  // 在created钩子中执行的操作
  created() {
  },
  updated() {
    console.log(this.imageUrl)
  }
};
</script>

<!-- 组件的样式 -->
<style scoped>
.customerUploadBase {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 90px;
  border: 1px solid #dcdfe6
}


.imgContain {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 90px;
  border: 1px solid #dcdfe6;
}

.imgContain img {
  max-width: 100%;
  height: auto;

}

.imgContain i {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

}

</style>
