<template>
  <div class="card-body pt-0">
    <!--begin::Table container-->
    <div class="table-responsive tableProduct">
      <!--begin::Table-->
      <!--                                    @selection-change="handleSelectionChange"-->
      <el-table :data="rows" ref="topicGoodsTable"
                empty-text="未查询到数据，请前往全国商品池或者分组挑选商品">
        <template #empty>
          <p>
            未查询到数据
          </p>
        </template>
        <!--                          <el-table-column-->
        <!--                              type="selection" width="50">-->
        <!--                          </el-table-column>-->
        <el-table-column
            label="商品信息"
            width="300"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-50px me-5"><img
                  :src="scope.row.picUrl.indexOf('https://www.3jzc.cn')===-1?scope.row.picUrl:`https://www.3jzc.cn${scope.row.picUrl}`"
                  class="" alt=""></div>
              <div class="d-flex justify-content-start flex-column cursor-pointer"><a
                  @click="openGoodInfo(scope.row.isJD==1?scope.row.skuId:scope.row.id,scope.row.isJD)"
                  class="text-muted  d-block fs-7  text-hover-primary ">{{ scope.row.title }} </a>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="分类"
            width="80"
        >
          <template slot-scope="scope">
                                <span class="text-muted  d-block fs-7">{{
                                    scope.row.categoryId1Name
                                  }} <br> {{ scope.row.categoryId2Name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="市场价"
            width="80"
        >
          <template slot-scope="scope">
            <a class="text-dark fw-bolder  d-block mb-1 fs-7">¥{{ scope.row.price }}</a>
          </template>
        </el-table-column>
        <el-table-column
            label="分销价"
            width="80"
        >
          <template slot-scope="scope">
            <a class="text-dark fw-bolder  d-block mb-1 fs-7">¥{{ scope.row.supplPrice }}</a>
          </template>
        </el-table-column>

        <el-table-column
            label="销售方式"
        >
          <template slot-scope="scope">
            <a class="text-muted d-block fs-7">{{ scope.row.startNum == 1 ? '一件代发' : `集采(${scope.row.startNum}个起售)` }}</a>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
        >
          <template slot-scope="scope">
            <el-button :underline="false" round size="mini" type="primary" @click="openBuyOneFromGoods(
                              scope.row
                            )">购买
            </el-button>&nbsp;&nbsp;
            <el-button :underline="false" round size="mini" style="margin-left: 0px" type="success"
                       @click="openBuyManyFromGoods(scope.row)">集采
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--end::Table-->
    </div>
    <!--end::Table container-->
    <!-- 分页按钮 -->
    <el-pagination style="display:flex;justify-content:flex-end;margin-top: 5px"
                  :page-sizes="[3]"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   layout="total, prev, pager, next, jumper"
                   :total="total"
                   background>
    </el-pagination>


    <!-- 修改或新增收货地址 -->
    <el-dialog :close-on-click-modal="false" :title="status == 1 ? '新增收货信息' : '编辑收货信息'"
               :visible.sync="createOrEdit"
               width="30%">
      <el-form :model="editAddressObj">
        <el-form-item label="收货人" style="width:100%">
          <el-input v-model="editAddressObj.name" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" style="margin-bottom:0">
        </el-form-item>
        <el-form :inline="true" label-position="top">
          <el-form-item label="省">
            <el-select v-model="editAddressObj.pro" style="width:120px" @change="proChange">
              <el-option v-for="item in proList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市">
            <el-select v-model="editAddressObj.city" style="width:120px" @change="cityChange">
              <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县(区)">
            <el-select v-model="editAddressObj.area" style="width:120px" @change="areaChange">
              <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道">
            <el-select v-model="editAddressObj.stree" style="width:120px">
              <el-option v-for="item in streeList" :key="item.id" :label="item.name" :value="item.kid"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="详细地址">
          <el-input v-model="editAddressObj.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editAddressObj.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-checkbox v-model="editAddressObj.isType" false-label="2" true-label="1">设置默认地址</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrEdit = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 收货地址列表 -->
    <el-dialog :visible.sync="addressListDialog" title="收货信息列表" width="50%">
      <el-table :data="addressList">
        <el-table-column label="收件人" property="name"></el-table-column>
        <el-table-column label="收件地址" property="proCity"></el-table-column>
        <el-table-column label="详细地址" property="address"></el-table-column>
        <el-table-column label="手机号" property="tel"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="selectAddress(scope.row)">选择</el-button>
            <el-button size="small" type="text" @click="editAddress(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text"
                       @click="setDefalt(scope.row)">设置默认
            </el-button>
            <el-button v-if="scope.row.isType == 2" size="small" type="text" @click="delAddress(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressListDialog = false">取 消</el-button>
        <el-button type="primary" @click="addressListDialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="商品信息" width="60%">
      <el-descriptions :column="1" border class="margin-top" title="">
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ itemTemp.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-mobile-phone"></i> -->
            市场价
          </template>
          {{ itemTemp.price | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-location-outline"></i> -->
            分销价
          </template>
          {{ itemTemp.supplPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-tickets"></i> -->
            销售方式
          </template>
          {{ itemTemp.startNum == 1 ? '一件代发' : `集采(${itemTemp.startNum}个起售)` }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            收件人信息
          </template>
          <el-descriptions :column="5" border direction="vertical" title="">
            <el-descriptions-item label="收件人">{{ addressObj.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ addressObj.tel }}</el-descriptions-item>
            <el-descriptions-item label="收件地址">{{ addressObj.proCity }}{{
                addressObj.address
              }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="操作">
              <el-link type="primary" @click="addAddress"><i
                  class="el-icon-plus el-icon--right"></i>新增
              </el-link>
              <el-link style="margin-left: 10px;" type="primary"
                       @click="moreAddress">更多
              </el-link>
            </el-descriptions-item>
          </el-descriptions>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            购买数量
          </template>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="payGoods">购 买</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="procurementDialogFormVisible" title="集采" width="30%">
      <el-form ref="collectiveProcurementFromRuleForm" :model="collectiveProcurementFrom" :rules="rules">
        <el-form-item label="联系人" label-width="120px" prop="contacts">
          <el-input v-model="collectiveProcurementFrom.contacts" placeholder="请输入联系人" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" label-width="120px" prop="tel">
          <el-input v-model="collectiveProcurementFrom.tel" placeholder="请输入手机号" size="small"
                    style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="数量" label-width="120px" prop="num">
          <el-input-number v-model="collectiveProcurementFrom.num" :min="1" size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="配送方式" label-width="120px" prop="shippingMethod">
          <el-select v-model="collectiveProcurementFrom.shippingMethod" placeholder="请选择" size="small">
            <el-option :value="1" label="一次性发货"></el-option>
            <el-option :value="2" label="分批发货"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配送区域" label-width="120px" prop="address">
          <el-input
              v-model="collectiveProcurementFrom.address"
              :rows="2"
              maxlength="100"
              placeholder="请输入地址"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item label="期望单价" label-width="120px" prop="price">
          <el-input v-model="collectiveProcurementFrom.price" placeholder="请输入期望单价" size="small"
                    @input="changeMessage($event)"
                    style="width: 60%"></el-input>
        </el-form-item>
        <!--        <el-form-item label="期望单价" label-width="120px" prop="price">-->
        <!--          <input v-model="collectiveProcurementFrom.price">-->
        <!--          <el-input v-model="collectiveProcurementFrom.price" placeholder="请输入内容"></el-input>-->
        <!--          <el-input-->
        <!--              v-model="collectiveProcurementFrom.price"-->
        <!--             >-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
              v-model="collectiveProcurementFrom.remark"
              :rows="2"
              maxlength="100"
              placeholder="请输入备注"
              show-word-limit
              style="width: 60%;"
              type="textarea">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="procurementDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {getVipGoodsApi} from "@/api/vipAddressBookApi";
import {
  addAddressApi,
  buyPoolGoodsApi,
  defaultAddressApi,
  deleteAddressApi,
  getAddressInfoApi,
  getAddressPageApi,
  getJdRegionListApi,
  updateAddressApi
} from "@/api/chooseGoodsApi";
import {vipCollectiveProcurementSaveApi} from "@/api/VipCollectiveProcurementApi";

export default {
  name:"VipGoods",
  props:{
    staffId:{
      type:Number,
    },
    globalListParams:{
      type:Object
    },
    queryParam:{
      type:Object
    },
    type:{
      type:Number
    }

  },
  data(){
    return{
      status: "",
      proChange: "",
      rows:[],
      addressObj: {},
      createOrEdit: false,
      addressListDialog: false,
      dialogVisible: false,
      procurementDialogFormVisible: false,
      itemTemp: "",
      num: "",
      editAddressObj: {
        name: "",
        tel: "",
        address: "",
        isType: 1,
        pro: 0,
        city: 0,
        area: 0,
        stree: 0
      },
      addressList: [],
      param:{
        current:1,
        size:3
      },
      total: 0,
      proList: [],
      cityList: [],
      areaList: [],
      streeList: [],
      collectiveProcurementFrom: {
        goodsId: "",
        contacts: "",
        tel: "",
        num: 1,
        shippingMethod: 1,
        remark: "",
        address: "",
        price: ""
      },
      rules: {
        contacts: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        tel: [
          {required: true, message: '请输入联系电话', trigger: 'blur'}
        ],
        num: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ],
        shippingMethod: [
          {required: true, message: '请选择配送方式', trigger: 'blur'}
        ],
        remark: [
          {required: false, message: '请输入备注', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ]
      }

    }
  },
  watch:{
    type(item1,item2){
      console.log(item1,item2)
    }
  },
  deactivated(){
    console.log("组件被卸载")
  },
  methods:{
    changeMessage() {
      this.$forceUpdate()
    },
    areaChange() {

    },
    cityChange() {

    },
    handleSizeChange(val) {
      this.param.current=val;
      this.getList()
    },
    handleCurrentChange(val) {
      this.param.current=val;
      this.getList()
    },
    openBuyOneFromGoods(item){
      this.itemTemp = item;
      this.num = 1;
      this.getDefaultAddressPage()
    },
    async getDefaultAddressPage() {
      let {data} = await getAddressPageApi({
        name: "",
        tel: "",
        isType: 1,
        current: 1,
        size: 50
      })
      data.records.forEach((e) => {
        if (e.isType == 1) {
          this.addressObj = e;
        }
      })
      this.dialogVisible = true
    },
    openBuyManyFromGoods(item) {
      let obj = JSON.parse(JSON.stringify(item))
      this.collectiveProcurementFrom = {
        goodsId: "",
        contacts: "",
        tel: "",
        num: 1,
        shippingMethod: 1,
        remark: ""
      }
      console.log(obj.supplPrice)
      this.collectiveProcurementFrom.goodsId = obj.id
      this.collectiveProcurementFrom.price = obj.supplPrice
      console.log(this.collectiveProcurementFrom)
      this.procurementDialogFormVisible = true
    },
    openGoodInfo(id,isJd){
      this.$emit("openGoodInfo",id,isJd)
    },
    async getList(){
      let categoryId1 = ""
      let categoryId2 = ""
      if(this.queryParam.categoryId1!="null" && this.queryParam.categoryId1!=null){
        categoryId1 = this.queryParam.categoryId1
      }
      if(this.queryParam.categoryId2!="null" && this.queryParam.categoryId2!=null){
        categoryId2 = this.queryParam.categoryId2
      }
      let {data}=await getVipGoodsApi({
        ...this.globalListParams,
        ...this.param,
        staffId:this.staffId,
        categoryOne:categoryId1,
        categoryTwo:categoryId2,
      })
      this.rows=data.rows;
      this.total=data.total;
    },
    async saveAddress() {
      let obj = {
        name: this.editAddressObj.name,
        pro: this.editAddressObj.pro,
        city: this.editAddressObj.city,
        area: this.editAddressObj.area,
        address: this.editAddressObj.address,
        tel: this.editAddressObj.tel,
        isType: this.editAddressObj.isType,
        stree: this.editAddressObj.stree
      }
      if (this.status == 2) {
        obj.id = this.editAddressObj.id
      }
      //修改
      if (this.status == 2) {
        let {data} = await updateAddressApi(JSON.stringify(obj))
      } else {
        //创建
        let {data} = await addAddressApi(obj)
      }
      this.moreAddress()
      this.getDefaultAddressPage()
      this.createOrEdit = false;
    },
    selectAddress(item) {
      this.addressObj = item
      this.addressListDialog = false
      this.$message({
        type: 'success',
        message: '选择成功!'
      });
    },
    async editAddress(item) {
      let {data} = await getAddressInfoApi({
        addressId: item.id
      })
      this.editAddressObj = data
      this.editAddressObj.pro = +data.pro
      this.editAddressObj.city = +data.city
      this.editAddressObj.area = +data.area
      this.editAddressObj.stree = +data.stree ? +data.stree : '';
      this.status = 2;
      this.proList = await this.initJdRegionList(0)
      this.cityList = await this.initJdRegionList(data.pro)
      this.areaList = await this.initJdRegionList(data.city)
      this.streeList = await this.initJdRegionList(data.area)
      this.createOrEdit = true;
    },
    async delAddress(item) {
      this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let {data} = await deleteAddressApi({
          addressId: item.id
        })
        let {data: res} = await getAddressPageApi({
          name: "",
          tel: "",
          isType: "",
          current: 1,
          size: 50
        })
        this.addressList = res.records;
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //购买商品
    payGoods() {
      this.$confirm('你确认购买当前商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let obj = {
          goodsId: this.itemTemp.id,
          supplyPrice: this.itemTemp.supplPrice,
          goodsIsJd: this.itemTemp.isJD,
          addressId: this.addressObj.id,
          goodsQuantity: this.num,
          isVipGoods: true
        }
        let {data} = await buyPoolGoodsApi(obj)
        this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '购买成功!'
        });
      }).catch(() => {

      });
    },
    async setDefalt(item) {
      let obj = {
        addressId: item.id,
      }
      let {data} = await defaultAddressApi(obj)
      this.moreAddress()
      // this.getDefaultAddressPage()
      // this.addressListDialog = false;
    },
    async moreAddress() {
      this.addressListDialog = true;
      let {data} = await getAddressPageApi({
        name: "",
        tel: "",
        isType: "",
        current: 1,
        size: 50
      })
      this.addressList = data.records;
    },
    addAddress() {
      this.status = 1;
      this.proList = this.initJdRegionList(0)
      this.editAddressObj = {
        name: "",
        tel: "",
        address: "",
        isType: "2",
        pro: "",
        city: "",
        area: "",
        stree: ""
      }
      this.createOrEdit = true;
    },
    submitFrom() {
      this.$refs["collectiveProcurementFromRuleForm"].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        await vipCollectiveProcurementSaveApi(this.collectiveProcurementFrom);
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.procurementDialogFormVisible = false
      });
    },
    async initJdRegionList() {
      id = 0
      console.log(id)
      //编辑时初始化省市区
      let {data} = await getJdRegionListApi({
        pId: id
      })
      return data;
    },
  },

  activated(){
    this.getList()
  },
  created() {
    this.getList()
  }
}
</script>
