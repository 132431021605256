import service from "@/utils/request"

export function getWxUserCountApi(params) {
    return service({
        url: '/wxUserApi/getUserCount',
        method: 'get',
        params
    })
}

export function getWxUserPageApi(params) {
    return service({
        url: '/wxUserApi/getWxUserPage',
        method: 'get',
        params
    })
}

export function updateUserScoreApi(params) {
    return service({
        url: '/wxUserApi/updateUserScore',
        method: 'get',
        params
    })
}

export function getWxUserScoreApi(params) {
    return service({
        url: '/wxUserApi/getWxShopUserScoreList',
        method: 'get',
        params
    })
}

export function updateUserLevelApi(params) {
    return service({
        url: '/wxUserApi/updateUserLevel',
        method: 'get',
        params
    })
}

export function getBindWxUserTelApi(params) {
    return service({
        url: '/wxUserApi/getBindWxUserTel',
        method: 'get',
        params
    })
}

export function exportUserExcel(params) {
    return service({
        url: '/wxUserApi/exportUserList',
        method: 'get',
        params: params,
        responseType: 'blob',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}





