<template>
  <div>
    <div ref="chartContainer" style="width: 100%;height: 300px;padding-top: 20px"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import {getMonthDay} from "@/utils/dateUtils";
import {getMonthOrderCountApi, getThirtyDaySalesRevenueApi} from "@/api/orderApi";

export default {
  name: "MonthPrice",
  data() {
    return {
      dayArray:[],
      chartOptions:  {
        title: {
          left: 'center',
          text: '近30天销量(元)',
        },
        xAxis: {
          type: 'category',
          data: getMonthDay().reverse()
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ],
      }
    }
  },
  methods: {
    async getThirtyDaySalesRevenue(){
      this.dataArr=[];
      let {data:res}=await getThirtyDaySalesRevenueApi();
      let arr=[]
      res.forEach(e=>{
        arr.push(e.revenue);
      })
      /*let {data:res}=await getMonthOrderCountApi();
      let arr=[]
      res.forEach(e=>{
        arr.push(e.count);
      })*/
      this.dataArr=arr
    },
    async initChart() {
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);
      await this.getThirtyDaySalesRevenue();
      this.chartOptions.series[0].data=this.dataArr;
      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
  },
  mounted() {

    this.$nextTick(() => {
      this.initChart();
    })
  }
}
</script>


<style scoped>

</style>