<!-- 订单列表 -->
<template>
  <div id="kt_post" class="post flex-column-fluid">
    <!--    顶部标题-->
    <TitleCard v-if="!params.wxShopId" style="margin: 10px auto; max-width: 97%" title="订单管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Order Management</li>
        </ul>
      </template>
    </TitleCard>
    <div
        class="container-fluid d-flex flex-stack flex-column-fluid"
        style="padding: 0 30px"
    >
      <form action="#" style="width: 100%">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Compact form-->
            <div class="d-flex align-items-center">
              <!--begin::Input group-->
              <div class="position-relative w-md-400px me-md-2">
                <span
                    class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                >
                  <svg
                      height="24px"
                      version="1.1"
                      viewBox="0 0 24 24"
                      width="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="none"
                        stroke-width="1"
                    >
                      <rect height="24" width="24" x="0" y="0"></rect>
                      <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                      ></path>
                      <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <input
                    id="sTitle"
                    v-model="params.orderNo"
                    class="form-control form-control-solid ps-10"
                    name="shopName"
                    placeholder="订单编号"
                    type="text"
                    value=""
                />
              </div>
              <!--end::Input group-->
              <!--begin:Action-->
              <div class="d-flex align-items-center">
                <div class="btn btn-primary me-5 btn-sm" @click="findOrder">
                  查询
                </div>
                <div class="btn btn-secondary me-5 btn-sm" @click="resetBtn">
                  重置
                </div>
                <a
                    id="kt_horizontal_search_advanced_link"
                    class="btn btn-link"
                    data-bs-toggle="collapse"
                    href="#kt_advanced_search_form"
                >高级查询</a
                >
              </div>

            </div>
            <!--end::Compact form-->

            <!--begin::高级搜索-->
            <div id="kt_advanced_search_form" class="collapse">
              <!--begin::Separator-->
              <div class="separator separator-dashed mt-9 mb-6"></div>
              <!--end::Separator-->
              <!--begin::Row-->
              <div class="row g-8 mb-8">
                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >下单时间</label
                  >
                  <br/>
                  <el-date-picker
                      v-model="orderDateRange"
                      :picker-options="pickerOptions"
                      align="right"
                      class="w-100"
                      end-placeholder="结束日期"
                      range-separator="至"
                      start-placeholder="开始日期"
                      type="daterange"
                      unlink-panels
                      value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >下单人手机号</label
                  >
                  <br/>
                  <el-input
                      v-model="params.telNum"
                      clearable
                      placeholder="请输入下单人手机号"
                  >
                  </el-input>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-3">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >收件人手机号</label
                  >
                  <br/>
                  <el-input
                      v-model="params.tel"
                      clearable
                      placeholder="请输入收件人手机号"
                  >
                  </el-input>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Advance form-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </form>
    </div>
    <div
        id="kt_content_container"
        class="container"
        style="
        margin-top: 10px;
        max-width: 98%;
        flex-direction: row !important;
        display: flex;
      "
    >
      <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" class="" style="margin-bottom: 10px">
            <!-- {{idArr}} {{ sumMoney }} -->
            <div class="card card-flush" style="position: relative">
              <div class="card-body">
                <ul
                    class="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-semibold"
                >

                  <li
                      v-for="(item, i) in orderStatus"
                      :key="i"
                      v-if="!item.hidden"
                      :style="i==1||i==4||i==5?'':'margin-left: 30px'"
                      class="nav-item"
                  >
                    <el-badge
                        :hidden="
                        item.count != 0 &&
                        (item.title == '待付货款' ||
                          item.title == '待发货' ||
                          item.title == '售后'||item.title == '待开票')
                          ? false
                          : true
                      "
                        :max="99"
                        :value="item.count"
                        class="item"
                    >
                      <a
                          :ref="'page'+(i + 1)"
                          :class="index === i + 1 ? 'active' : ''"
                          :href="`#kt_tab_pane_${i + 1}`"
                          :style="
                          item.count != 0 &&
                          (item.title == '待付货款' ||
                            item.title == '待发货' ||
                            item.title == '售后')
                            ? `margin-right: 8px`
                            : ``
                        "
                          class="nav-link text-active-primary"
                          data-bs-toggle="tab"
                          style="
                          font-size: 13px;
                          font-weight: bold;
                          text-align: center
                        "
                          @click="jumpIndex(i + 1)"
                      >
                        <i :class="item.icon"></i>{{ item.title }}</a
                      >
                    </el-badge>
                  </li>
                </ul>
                <div style="position: absolute; top: 10px; right: 80px">
                  <el-button
                      size="small"
                      type="primary"
                      @click="dialogVisible = true"
                  >批量发货
                  </el-button
                  >
                  <!-- @click="batchDelivery" -->
                  <!-- https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/finance/%E6%89%B9%E9%87%8F%E5%8F%91%E8%B4%A7%E6%A8%A1%E6%9D%BF.xlsx -->
                  <el-button
                      icon="el-icon-download"
                      size="small"
                      type="primary"
                      @click="exp"
                  >导出订单
                  </el-button
                  >
                </div>
                <AllOrder
                    ref="AllOrder"
                    @reload="reload"
                    @setIdArr="setIdArr"
                    @setMoney="setMoney"
                ></AllOrder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="批量发货" width="30%">
      <div style="display: flex; justify-content: center">
        <el-upload
            ref="upload"
            :auto-upload="false"
            :file-list="fileList"
            :http-request="handleChange"
            :on-change="onChange"
            accept=".xlsx"
            action="string"
            class="upload-demo"
        >
          <!-- :show-file-list="false" -->
          <a class="btn btn-sm btn-primary mx-2" href="javascript:void(0)">
            上传发货模板
          </a>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-link
            :underline="false"
            href="https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/finance/%E6%89%B9%E9%87%8F%E5%8F%91%E8%B4%A7%E6%A8%A1%E6%9D%BF.xlsx"
            icon="el-icon-download"
            style="margin-right: 20px"
        >下载发货模板</el-link
        >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchDelivery">提 交</el-button>
      </span>
    </el-dialog>
    <ModalDialog ref="outcomeDialog" title="失败结果" width="50%">
      <div style="margin-bottom: 60px;">
        <el-table v-if="data.errorCount!=0" :data="tableData" style="width: 100%">
          <el-table-column label="订单编号" prop="orderNo"></el-table-column>
          <el-table-column label="快递名字" prop="expressName" width="180">
          </el-table-column>
          <el-table-column label="快递单号" prop="expressNo" width="180">
          </el-table-column>
          <el-table-column label="字母单号(使用#)" prop="otherExpressNo">
          </el-table-column>
          <el-table-column label="错误信息" prop="errorMsg">
          </el-table-column>
        </el-table>
      </div>

      <div style="position: relative;">
        <div style="position: absolute;bottom: 0px;right: 0;">
          <el-link
              :underline="false"
              icon="el-icon-download"
              style="margin-right: 20px"
              @click="exportExcel"
          >下载失败结果
          </el-link
          >
          <el-button @click="$refs.outcomeDialog.close()">关 闭</el-button>
        </div>
      </div>
    </ModalDialog>

  </div>
</template>

<script>
import AllOrder from "./components/AllOrders.vue";
import PendingPayment from "./components/PendingPayment.vue";
import sd from "silly-datetime";
import {
  batchOrderPaymentApi,
  checkCartOrderApi,
  exportOrderApi,
  getOrderListCountApi,
  updateBatchOrderDeliveryApi,
} from "@/api/orderApi";
import {getMyCashOut} from "@/api/sjCashOutApi";
import {formatMoney} from "@/utils/formatMoney";
import * as XLSX from "xlsx";
import {mapState} from "vuex";

export default {
  name: "",
  components: {
    AllOrder,
    PendingPayment,
  },
  computed: {
    ...mapState("user", ["role"]),
  },
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    currentWxShopId: {
      type: Number,
      default: null,
    },
    userTel: {
      type: String,
      default: "",
    }
  },
  // 组件的data属性
  data() {
    return {
      data: {
        successCount: 0,
        errorCount: 1,
      },
      columns: [
        {label: "订单编号", prop: "orderNo"},
        {label: "快递名字", prop: "expressName"},
        {label: "快递单号", prop: "expressNo"},
        {label: "字母单号(使用#)", prop: "otherExpressNo"},
        {label: "错误信息", prop: "errorMsg"},
      ],
      outcomeDialog: false,
      fileList: [],
      tableData: [],

      dialogVisible: false,
      index: 1,
      idArr: [],
      orderDateRange: [],
      orderStatus: [
        {
          title: "全部订单",
          count: 0,
          icon: "ki-outline ki-bookmark-2 fs-4 me-2",
          hidden:false
        },
        {
          title: "待付货款",
          count: 0,
          icon: "ki-outline ki-note-2 fs-4 me-2",
          hidden: true
        },
        {
          title: "待发货",
          count: 0,
          icon: "ki-outline ki-brifecase-timer fs-4 me-2",
          hidden:false
        },
        {
          title: "已发货",
          count: 0,
          icon: "ki-outline ki-briefcase fs-4 me-2",
          hidden:false
        },
        {
          title: "已完成",
          count: 0,
          icon: "ki-outline ki-brifecase-tick fs-4 me-2",
          hidden:false
        },
        {
          title: "售后",
          count: 0,
          icon: "ki-outline ki-bookmark fs-4 me-2",
          hidden: true
        }, {
          title: "待开票",
          count: 0,
          icon: "ki-outline ki-bookmark fs-4 me-2",
          hidden: true
        },
      ],
      params: {
        pageIndex: 1,
        pageSize: 10,
        // 订单编号
        orderNo: "",
        // 订单状态
        orderStatus: "",
        // 开票状态
        invoiceState: "",
        // 订单日期
        startTime: "",
        endTime: "",
        // 下单人手机号
        telNum: this.userTel,
        // 商品来源
        fromType: "",
        wxShopId: this.currentWxShopId == null ? "" : this.currentWxShopId,
        // 收件人手机号
        tel:""
      },
      orderStatusOptions: [
        {
          value: "1",
          label: "待付款",
        },
        {
          value: "2",
          label: "待发货",
        },
        {
          value: "3",
          label: "已发货",
        },
        {
          value: "4",
          label: "已完结",
        },
      ],
      invoiceStateOptions: [
        {
          value: "1",
          label: "已开票",
        },
        {
          value: "2",
          label: "未开票",
        },
      ],
      sourceOfGoodsOptions: [
        {
          value: "1",
          label: "自有商品",
        },
        {
          value: "4",
          label: "厂供商品",
        },
        {
          value: "3",
          label: "全国商品池",
        },
        {
          value: "2",
          label: "二级供应商",
        },
      ],
      //日期选择器 用
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      myCashOut: {
        money: "",
      },
      sumMoney: 0,
    };
  },
  // 组件的方法
  methods: {
    formatM(num) {
      if (!isNaN(num)) {
        return formatMoney(num);
      }
      if (num == null) {
        return "-";
      }
      return num;
    },
    async checkCartOrder() {
      let {data: res} = await checkCartOrderApi({
        orderNos: this.idArr
      })
      this.$confirm(
          `确定支付 ${this.formatM(res.totalMoney)} 吗?`,
          "支付",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(async () => {
            let {data} = await batchOrderPaymentApi({orderNos: res.orderNoList});
            this.$swal.fire({
              icon: "success",
              title: "支付成功",
              toast: true,
              position: "center",
              showConfirmButton: false,
              timer: 2000,
            });
            // this.$emit("reload")
            this.findOrder();
          })
          .catch(() => {
          });
    },
    reload() {
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    async handleChange(param) {
      if (param.file.size >= 100 * 1024 * 1024) {
        this.$message({
          message: "文件过大,最大100MB",
          type: "warning",
        });
        return;
      }
      const data = new FormData();
      data.append("file", param.file);
      let {data: res} = await updateBatchOrderDeliveryApi(data);

      this.fileList = [];
      if (this.data.errorCount) {
        this.$refs.outcomeDialog.open()
      }
      this.dialogVisible = false;
      this.data = res;
      this.tableData = res.errorDataList;
      this.$swal.fire({
        icon: "success",
        text: `成功处理${this.data.successCount}，失败 ${this.data.errorCount}`,
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.findOrder();
    },
    batchDelivery() {
      if (this.fileList.length > 0) {
        this.$refs.upload.submit();
      } else {
        this.$message({
          message: "请上传发货模板",
          type: "warning",
        });
      }
    },
    exportExcel() {
      const header = this.flattenHeaders(this.columns);
      const data = this.tableData.map((item) =>
          this.flattenData(item, this.columns)
      );

      const worksheet = XLSX.utils.json_to_sheet([header, ...data], {
        skipHeader: true,
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(workbook, "错误数据.xlsx");
    },
    flattenHeaders(columns) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          acc.push(...this.flattenHeaders(column.children));
        } else {
          acc.push(column.label);
        }
        return acc;
      }, []);
    },
    flattenData(data, columns) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          acc.push(...this.flattenData(data, column.children));
        } else {
          acc.push(data[column.prop]);
        }
        return acc;
      }, []);
    },
    setMoney(money) {
      this.sumMoney = money;
    },
    setIdArr(ids) {
      this.idArr = ids;
    },
    resetBtn() {
      this.params = {
        pageIndex: 1,
        pageSize: 10,
        // 订单编号
        orderNo: "",
        // 订单状态
        orderStatus: "",
        // 开票状态
        invoiceState: "",
        // 订单日期
        startTime: "",
        endTime: "",
        // 下单人手机号
        telNum: this.userTel,
        // 商品来源
        fromType: "",
        wxShopId: this.currentWxShopId == null ? "" : this.currentWxShopId,
        tel:""
      };
      this.jumpIndex(1);
      this.findOrder();
    },
    async exp() {
      let json = {
        orderNo: this.params.orderNo,
        orderStatus: this.params.orderStatus,
        invoiceState: this.params.invoiceState,
        telNum: this.params.telNum,
        orderType: this.params.orderType,
        startTime: this.params.startTime,
        fromType: this.params.fromType,
        endTime: this.params.endTime,
        wxShopId: this.params.wxShopId
      };
      let response = await exportOrderApi(json);
      var blob = new Blob([response], {type: "application/octet-stream"});
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "订单数据.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    jumpIndex(index) {

      this.index = index;
      this.params.orderStatus = this.index;
      this.findOrder();
    },

    findOrder() {
      this.params.startTime = "";
      this.params.endTime = "";
      if (this.orderDateRange && this.orderDateRange.length > 0) {
        this.params.startTime = sd.format(
            this.orderDateRange[0],
            "YYYY-MM-DD 00:00:00"
        );
        this.params.endTime = sd.format(
            this.orderDateRange[1],
            "YYYY-MM-DD 00:00:00"
        );
      }
      this.getOrderSjListCount();
      this.$refs.AllOrder.findOrder(this.params);
    },
    async getOrderSjListCount() {
      let {data} = await getOrderListCountApi(this.params);
      // 待付货款
      this.orderStatus[1].count = data.pendingPaymentCount;
      // 待发货
      this.orderStatus[2].count = data.toBeShippedCount;
      // 售后
      this.orderStatus[5].count = data.afterSalesCount;
      this.orderStatus[6].count = data.pendingInvoiceCount;
    },
    async getMyCashOut() {
      let {data} = await getMyCashOut();
      this.myCashOut = data.info;
    },
  },
  // 在created钩子中执行的操作
  created() {
    //this.getOrderSjListCount();

    if (this.$route.query.index) {
      this.$nextTick(() => {
        let dom = "page" + this.$route.query.index;
        this.$refs[dom][0].click()
      })

    } else {
      this.$nextTick(() => {
        this.findOrder()
      })
    }


  },
  filters: {
    dataFormat(date) {
      return sd.format(date, "YYYY-MM-DD HH:mm:ss");
    },
    format(num) {
      return (
          Number(num)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,") + " 元"
      );
    },
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
.el-range-separator {
  padding: 0;
}
</style>
