<!-- 组件模板内容 -->
<template>
  <div>
    <TitleCard title="分组管理" style="max-width: 97%; margin: 10px auto">
      <template v-slot:subTitle class="position-relative">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li
              class="breadcrumb-item text-muted me-0"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif"
          >
            Group Management
          </li>
          <div style="position: absolute; right: 0; padding: 0 40px">
            <button
                type="button"
                class="el-button el-button--primary el-button--mini"
                style="margin-right: 0px"
                @click="openCreate"
            >
              <i class="el-icon-edit"></i><span> 创建分组</span>
            </button>
          </div>
        </ul>
      </template>
    </TitleCard>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          style="max-width: 98%; margin-bottom: 15px; min-height: 85vh"
          id="kt_content_container"
          class="container"
      >
        <!-- 列表 -->
        <div class="card" element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body" style="padding: 1.2rem 1rem">
            <div class="table-responsive">
              <table
                  class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                  id="kt_ecommerce_products_table"

              >
                <thead>
                <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                      style="width: 100px"
                  >
                    分组信息
                  </th>
                  <th
                      class="sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品数"
                      style="width: 80px"
                  >
                    商品数
                  </th>
                  <!--                  <th
                                        class="sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="利润率"
                                        style="width: 80px"
                                    >
                                      利润率
                                    </th>-->
                  <th
                      class="sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                      style="width: 80px"
                  >
                    状态
                  </th>
                  <th
                      class="sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="创建时间"
                      style="width: 80px"
                  >
                    创建时间
                  </th>
                  <th
                      class="sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="更新时间"
                      style="width: 80px"
                  >
                    更新时间
                  </th>
                  <th
                      class="sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label="操作"
                      style="width: 110px"
                  >
                    操作
                  </th>
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr v-for="(item, index) in groupRow" :key="index">
                  <td>
                    <div
                        class="d-flex align-items-center"
                        @click="editGroup(item)"
                    >
                      <a
                          href="javascript:void(0);"
                          class="symbol symbol-50px"
                      >
                        <img
                            class="symbol-label lozad"
                            :src="item.imageUrl"
                        />
                      </a>
                      <div class="ms-5">
                        <a
                            href="javascript:void(0);"
                            class="text-gray-800 text-hover-primary fs-5 fw-bold"
                            data-kt-ecommerce-product-filter="product_name"
                        >{{ item.name }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ item.goodsTotal ? item.goodsTotal : "0" }}
                  </td>
                  <!--                  <td>{{ item.profitRate ? item.profitRate : "0" }}%</td>-->
                  <td>
                    <el-switch
                        v-model="item.isShow"
                        active-text="启动"
                        :active-value="1"
                        :inactive-value="2"
                        inactive-text="关闭"
                        @change="changeIsShow(item.id, item.isShow)"
                    >
                    </el-switch>
                  </td>
                  <td>
                    {{ item.createTime }}
                  </td>
                  <td>
                    {{ item.updateTime }}
                  </td>
                  <td>
                    <el-button size="mini" type="primary"
                               @click="
                              $router.push({
                                name: 'GroupDetail',
                                params: { id: item.id },
                              })
                            "
                    >分组详情
                    </el-button>
                    <el-button v-if="item.isShow === 1"  size="mini" type="success"
                               @click="pushAll(item.id)"
                    >一键推送
                    </el-button>
                    <el-button v-else disabled size="mini" type="success">一键推送</el-button>
                    <el-button  size="mini" type="danger"
                    @click="openDeleteWindow(item.id)">删除</el-button>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :total-items="groupPageTotal"
                  @page-change="pageGroupChange"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改分组信息 -->
    <ModalDialog
        :title="groupType"
        id="createGroup"
        ref="createGroup"
        width="50%"
    >
      <el-form ref="form" :model="createGroupObj" label-width="80px">
        <el-form-item label="分组名" :required="true">
          <el-input v-model="createGroupObj.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" :required="true">
          <el-switch
              v-model="createGroupObj.isShow"
              active-text="启动"
              :active-value="1"
              :inactive-value="2"
              inactive-text="关闭"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="分组图片" :required="true">
          <UploadFile
              v-if="groupType == '创建分组'"
              moduleName="groupImage"
              :limit="1"
              @upload-success="UploadFileSuccess"
          >
          </UploadFile>
          <el-upload
              v-else
              class="upload-demo"
              ref="upload"
              action="string"
              :auto-upload="true"
              :http-request="handleChange"
              :show-file-list="false"
          >
            <el-avatar
                shape="square"
                :size="100"
                fit="fill"
                :src="createGroupObj.imageUrl"
            ></el-avatar>
            点击头像进行修改
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
              type="primary"
              @click="createGroup"
              v-if="groupType == '创建分组'"
          >立即创建
          </el-button
          >
          <el-button type="primary" @click="saveGroup" v-else
          >立即保存
          </el-button
          >
          <el-button @click="$refs.createGroup.close()">取消</el-button>
        </el-form-item>
      </el-form>
    </ModalDialog>
    <!-- 推送 -->
    <ModalDialog
        title="挑选上架"
        id="pushGoodsAll"
        ref="pushGoodsAll"
        width="850px"
    >
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item label="推送目标" :required="true">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects">
              <el-checkbox label="shop">轮播</el-checkbox>
              <el-checkbox label="topic">活动</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ this.sjGoodsArr.length + this.jdGoodsArr.length }} </span> 件商品
          </el-form-item>
<!--          <el-form-item
              label="销售价"
              :required="pushGoodsObj.salePriceChange == '1'"
          >
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="1">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select
                v-model="pushGoodsObj.salePriceDto.salePriceReference"
                style="width: 150px"
                placeholder="请选择"
                :disabled="pushGoodsObj.salePriceChange != 1"
                size="small" @change="changeSalePrice"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-select
                v-model="pushGoodsObj.salePriceDto.salePriceAdjust"
                style="width: 150px"
                placeholder="请选择"
                size="small"
                :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                  v-for="item in adjustOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-input
                v-model="pushGoodsObj.salePriceDto.salePriceNum"
                placeholder="请输入内容"
                size="small"
                style="width: 150px"
                :disabled="pushGoodsObj.salePriceChange != 1"
            ></el-input>
            &nbsp;
            <el-select
                style="width: 100px"
                v-model="pushGoodsObj.salePriceDto.salePriceUnit"
                placeholder="请选择"
                size="small"
                :disabled="pushGoodsObj.salePriceChange != 1"
            >
              <el-option
                  v-for="item in unitOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <br/>
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>-->
          </el-form-item>
        </el-form>
      </div>
      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="nextPush"
      >下一步<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
    </ModalDialog>
    <!-- 显示具体的推送范围-->
    <ModalDialog
        title="选择具体推送目标"
        id="pushGrounp"
        ref="pushGrounp"
        :width="pushGoodsObj.selectionObjects.length * 400 + 'px'"
    >
      <div style="display: flex">
        <div
            style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
            v-if="pushGoodsObj.selectionObjects.includes('shop')"
        >
          <h3>轮播</h3>
          <div>
            <el-tree
                :data="shopProps"
                show-checkbox
                node-key="id"
                ref="shopTree"
                @check="shopTreeNodeClick"
            >
            </el-tree>
          </div>
        </div>
        <div
            style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
            v-if="pushGoodsObj.selectionObjects.includes('topic')"
        >
          <h3>活动</h3>
          <div>
            <el-tree
                :data="topicProps"
                ref="topicTree"
                node-key="id"
                show-checkbox
                @check="topicTreeNodeClick"
            >
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="submitPush"
      >提交&nbsp;&nbsp;<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
      <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="$refs.pushGrounp.close()"
      >上一步<i class="el-icon-arrow-left el-icon--left"></i
      ></el-button>
    </ModalDialog>
  </div>
</template>

<script>
import {
  addGoodsGroupApi,
  deleteGroupApi,
  getGoodsGroupApi,
  getGoodsListByGroupIdApi,
  getWxShopByStaffIdApi,
  getWxTopicByStaffIdApi,
  selectionGoodsApi,
  updateGoodsGroupApi
} from "@/api/groupGoodsApi";
//import {selectionGoodsApi} from "@/api/chooseGoodsApi";
import {uploadFileApi} from "@/api/uploadFileApi";
import GroupDetail from "./components/GroupDetail"


export default {
  name: "GroupManage",
  components: {
    GroupDetail
  },
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      groupType: "创建分组",
      jdGoodsArr: [],
      sjGoodsArr: [],
      groupParams: {
        current: 1,
        size: 10,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      groupRow: [],
      groupPageTotal: 0,
      file: "",
      isShow: true,
      createGroupObj: {
        name: "",
        isShow: 1,
        imageUrl: "",
      },
      loading: true,
      pushGoodsObj: {
        topicIds: [],
        shopIds: [],
        selectionObjects: [],
        goodsIds: [],
        isJd: 1,
        salePriceChange: "2",
        supplPriceChange: "2",
        salePriceDto: {
          salePriceReference: "2",
          salePriceAdjust: "2",
          salePriceNum: "",
          salePriceUnit: "1",
        },
      },
      groupProps: [],
      shopProps: [],
      topicProps: [],
      unitOptions: [
        {
          value: "1",
          label: "%",
        },
        {
          value: "2",
          label: "元",
        },
      ],
      adjustOptions: [
        //{
        //  value: "1",
        //  label: "上调",
        //},
        {
          value: "2",
          label: "下降",
        },
      ],
      options: [
        //{
        //  value: "1",
        //  label: "销售价",
        //},
        {
          value: "2",
          label: "市场价",
        },
        {
          value: "3",
          label: "分销价",
        },
      ],
      salePriceReference: "2",
      groupIds: [],
      userRole:localStorage.getItem('role')
    };
  },
  // 组件的方法
  methods: {
    // 批量推送弹窗信息
    pushAll: async function (item) {
      console.log(item)
      this.jdGoodsArr = [];
      this.sjGoodsArr = [];
      let {data} = await getGoodsListByGroupIdApi({groupId: item});
      for (let i = 0; i < data.length; i++) {
        if (data[i].isJd === 1) {
          this.jdGoodsArr.push(data[i].goodsId)
        } else if (data[i].isJd === 2) {
          this.sjGoodsArr.push(data[i].goodsId)
        }
      }
      this.id = "";
      if (this.sjGoodsArr.length < 1 && this.jdGoodsArr.length < 1) {
        return;
      }
      this.shopProps = [];
      this.topicProps = [];
      let {data: res1} = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let {data: res2} = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.title,
        });
      });
      this.$refs.pushGoodsAll.open();
    },
    // 批量推送下一页
    async nextPush() {
      if (this.pushGoodsObj.selectionObjects.length == 0) {
        this.$message({
          message: "请选择推送目标",
          type: "warning",
        });
        return;
      }
      this.$refs.pushGrounp.open();
    },
    // 将数据推送到后端
    async submitPush() {
      this.pushGoodsObj.topicIds = [];
      this.pushGoodsObj.shopIds = [];

      if (this.pushGoodsObj.selectionObjects.includes("topic")) {
        this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
        if (this.pushGoodsObj.topicIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("shop")) {
        this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
        if (this.pushGoodsObj.shopIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      /*if (this.pushGoodsObj.salePriceChange == "2") {
        this.pushGoodsObj.salePriceDto = {
          salePriceReference: "",
          salePriceAdjust: "",
          salePriceNum: "",
          salePriceUnit: "",
        };
      }*/
      // 推送sj的商品
      let total = 0;
      let goodNum = this.jdGoodsArr.length + this.sjGoodsArr.length;
      if (this.sjGoodsArr.length >= 1) {
        this.pushGoodsObj.goodsIds = this.sjGoodsArr;
        this.pushGoodsObj.isJd = 2;

        let arr = ["shop", "topic"];
        let arr1 = [];
        arr.forEach((item) => {
          if (this.pushGoodsObj.selectionObjects.includes(item)) {
            arr1.push(item);
          }
        });

        let {data} = await selectionGoodsApi(this.pushGoodsObj);
        /*for (let key in data) {
          if (data.hasOwnProperty(key)) {
            total += data[key];
          }
        }
        if (total === 0) {
          this.$message({
            message: "全部商品推送成功",
            type: "success",
          });
        } else if (total >= goodNum) {
          this.$message({
            message: "商品全部推送失败，请检查商城已存在商品!",
            type: "error",
          });
        } else if (total < goodNum) {
          this.$message.warning("商品部分推送失败，部分商品已被推送!")
        }*/

      }
      // 推送jd的商品
     /* if (this.jdGoodsArr.length >= 1) {
        this.pushGoodsObj.goodsIds = this.jdGoodsArr;
        this.pushGoodsObj.isJd = 1;

        let arr = ["shop", "topic"];
        let arr1 = [];
        arr.forEach((item) => {
          if (this.pushGoodsObj.selectionObjects.includes(item)) {
            arr1.push(item);
          }
        });
        let {data} = await selectionGoodsApi(this.pushGoodsObj);
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            total += data[key];
          }
        }
        if (total === 0) {
          this.$message({
            message: "全部商品推送成功",
            type: "success",
          });
        } else if (total >= goodNum) {
          this.$message({
            message: "商品全部推送失败，请检查商城已存在商品!",
            type: "error",
          });
        } else if (total < goodNum) {
          this.$message.warning("商品部分推送失败，部分商品已被推送!")
        }
      }*/

      this.$message({
        message: "推送成功",
        type: "success",
      });
      this.jdGoodsArr = [];
      this.sjGoodsArr = [];
      this.$refs.pushGoodsAll.close();
      this.$refs.pushGrounp.close();
    },
    //
    async handleChange(param) {
      const data = new FormData();
      if (param) {
        data.append("file", param.file);
        data.append("moduleName", "groupImage");
        if (param.file.size >= this.fileSize * 1024 * 1024) {
          this.$message({
            message: "文件过大,最大" + this.fileSize + "MB",
            type: "warning",
          });
          return;
        }
      }
      let {data: res} = await uploadFileApi(data);
      this.createGroupObj.imageUrl = res.fileUrl
      this.$message({
        message: "上传成功!",
        type: "success",
      });
    },
    async saveGroup() {
      let obj = JSON.parse(JSON.stringify(this.createGroupObj));
      delete obj.createTime;
      delete obj.goodsTotal;
      delete obj.profitRate;
      delete obj.updateTime;
      obj.goodsGroupIds = [obj.id];
      delete obj.id;
      let {data} = await updateGoodsGroupApi(JSON.stringify(obj));
      this.$refs.createGroup.close();
      this.getGoodsGroup();
    },
    editGroup(item) {
      this.groupType = "修改分组";
      this.createGroupObj = JSON.parse(JSON.stringify(item));
      this.$refs.createGroup.open();
    },
    openCreate() {
      this.createGroupObj = {
        name: "",
        isShow: 1,
        imageUrl: "",
      };
      this.createGroupObj.isShow = 1;
      this.groupType = "创建分组";
      this.$refs.createGroup.open();
    },
    async changeIsShow(id, isShow) {
      let {data} = await updateGoodsGroupApi(
          JSON.stringify({
            goodsGroupIds: [id],
            isShow,
          })
      );
      this.getGoodsGroup();
    },
    async createGroup() {
      if (!this.ruleForm()) {
        return;
      }
      let {data} = await addGoodsGroupApi(this.createGroupObj);
      this.$refs.createGroup.close();
      this.getGoodsGroup();
    },
    UploadFileSuccess(file) {
      let {data} = file;
      this.createGroupObj.imageUrl = data.fileUrl;
    },

    // 表单规则
    ruleForm() {
      //检查分组名
      if (!this.createGroupObj.name) {
        this.$swal.fire({
          icon: "warning",
          title: "请上填写分组名",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查图片
      if (!this.createGroupObj.imageUrl) {
        this.$swal.fire({
          icon: "warning",
          title: "请上填写分组图片",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }

      return true;

    },

    pageGroupChange(currentPage, pageSize) {
      this.groupParams.current = currentPage;
      this.groupParams.size = pageSize;
      this.getGoodsGroup();
    },
    async getGoodsGroup() {
      this.loading = true;
      let {data} = await getGoodsGroupApi(this.groupParams);
      this.groupRow = data.rows;
      this.groupPageTotal = data.total;
      this.loading = false;
    },
    // 按照价格改变时 调整方式需要改变
    changeSalePrice () {
      if (this.pushGoodsObj.salePriceDto.salePriceReference == 2) {
        this.pushGoodsObj.salePriceDto.salePriceAdjust = "2";
        this.adjustOptions = [
          {
            value: "2",
            label: "下降",
          },
        ]
      } else {
        this.adjustOptions = [
          {
            value: "1",
            label: "上调",
          },
          {
            value: "2",
            label: "下降",
          },
        ]
      }
    },
    openDeleteWindow(groupId){
      let msg = "";
      if (groupId) {
        msg = "你是否删除该分组";
        this.groupIds = [groupId];
      } else {
        msg = "你是否删除选中的所有商品";
      }
      let arr = Array.from(this.groupIds)
      this.$confirm(`${msg}`, `是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        await deleteGroupApi(arr);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getGoodsGroup();
      }).catch(() => {
      });
    },
    // 将多选改成单选
    setTree(data,typeName){
      this.$refs[typeName].setCheckedKeys([]);
      if (data){
        this.$refs[typeName].setCheckedNodes([data]);
      }
    },
    shopTreeNodeClick(data){
      this.setTree(data,"shopTree")
    },
    topicTreeNodeClick(data){
      this.setTree(data,"topicTree")
    },
  },
  // 在created钩子中执行的操作
  created() {
    this.getGoodsGroup();
  },

};
</script>

<!-- 组件的样式 -->
<style scoped>

</style>
