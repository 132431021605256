import { render, staticRenderFns } from "./UserComponent.vue?vue&type=template&id=c83a2786"
import script from "./UserComponent.vue?vue&type=script&lang=js"
export * from "./UserComponent.vue?vue&type=script&lang=js"
import style0 from "./UserComponent.vue?vue&type=style&index=0&id=c83a2786&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports