<template>
  <div style="margin-top: 10px">
    <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
    <el-tabs @tab-click="handleTabClick">
      <el-tab-pane  class="customTab">
        <span slot="label" >
          <label id="tab1" class="btn btn-active-light-primary p-7 d-flex align-items-center bg-white active" style="border:1px solid #009ef7;width: 250px">
																			<i class="ki-outline ki-badge fs-3x me-5"></i>
																			<span class="d-block fw-semibold text-start">
																				<span class="text-dark fw-bold d-block fs-2 mb-2">通讯录</span>
																				<span class="text-muted d-block fw-semibold fs-7">方便沟通，高效寻找货源</span>
																			</span>
																		</label>
        </span>
        <addressBook></addressBook>
      </el-tab-pane>
      <el-tab-pane  class="centered-tabs">
        <span slot="label">
          <label id="tab2" class="btn  btn-active-light-primary p-7 d-flex align-items-center bg-white" style="border:1px solid #009ef7;width: 250px">
																			<i class="ki-outline ki-burger-menu fs-3x me-5"></i>
            <!--begin::Info-->
																			<span class="d-block fw-semibold text-start">
																				<span class="text-dark fw-bold d-block fs-2 mb-2">公告栏</span>
																				<span class="text-muted fw-semibold fs-7">时刻关注协会动态</span>
																			</span>
            <!--end::Info-->
																		</label>
        </span>
      <bulletin-board></bulletin-board>
      </el-tab-pane>
      <el-tab-pane  class="centered-tabs" >
        <span slot="label">
          <label id="tab3" class="btn  btn-active-light-primary p-7 d-flex align-items-center bg-white" style="border:1px solid #009ef7;width: 250px">
																			<i class="ki-outline ki-book-open fs-3x me-5"></i>
            <!--begin::Info-->
																			<span class="d-block fw-semibold text-start">
																				<span class="text-dark fw-bold d-block fs-2 mb-2">读书会</span>
																				<span class="text-muted fw-semibold fs-7">分享读书心得,活到老学到老</span>
																			</span>
            <!--end::Info-->
																		</label>
        </span>
        <read-book></read-book>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import {mixins} from "@/views/VipUser/mixins";
import addressBook from "@/views/VipUser/component/addressBook.vue";
import bulletinBoard from "@/views/VipUser/component/bulletinBoard.vue";
import ReadBook from "@/views/VipUser/component/readBook.vue";

export default {
  name: "VipUser",
  components:{ReadBook, addressBook,bulletinBoard},
  mixins: [mixins]

}
</script>
<style scoped>

::v-deep .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav  .el-tabs__item{
  padding: 0 20px;
  height: 110px;
  box-sizing: border-box;
  line-height: 110px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  position: relative;
}
</style>
