import service from "@/utils/request"

export function getShopListApi(data) {
    return service({
        url: '/wxShop/getWxShopList',
        method: 'get',
        params:data
    })
}

export function getMacSelect(data){
    return service({
        url: '/wxShop/getMacList',
        method: 'get',
        params:data
    })
}

export function getWxShopTemplateList(data) {
    return service({
        url: '/wxShop/getWxShopTemplateList',
        method: 'get',
        params:data
    })
}

export function addWxShop(data){
    return service({
        url: '/wxShop/addWxShop',
        method: 'post',
        data:data
    })
}
export function updateWxShop(data){
    return service({
        url: '/wxShop/updateWxShop',
        method: 'post',
        data:data
    })
}
export function getWxShopBaseInfo(data){
    return service({
        url: '/wxShop/getWxShopBaseInfo',
        method: 'get',
        params:data
    })
}

export function copyWxShop(data){
    return service({
        url: '/wxShop/copyWxShop',
        method: 'get',
        params:data
    })
}

export function getWxshopChooseTopic(data){
    return service({
        url: '/wxShop/getWxshopChooseTopic',
        method: 'get',
        params:data
    })
}

export function updateWxShopStatus(data){
    return service({
        url: '/wxShop/updateWxShopStatus',
        method: 'post',
        params:data
    })
}

export function getRecommendShop(data){
    return service({
        url: '/wxShop/getSystemShopList',
        method: 'post',
        params:data
    })

}

export function copySystemWxShop(data){
    return service({
        url: '/wxShop/copySystemWxShop',
        method: 'post',
        data
    })
}


//获取店铺客服电话和销售电话
export function getWxShopTelAip() {
    return service({
        url: '/wxMac/getWxShopTel',
        method: 'get',
    })
}

//保存店铺客服电话和销售电话
export function updateShopTel(data) {
    return service({
        url: '/wxMac/updateWxShopTel',
        method: 'put',
        data
    })
}
