<!-- 订单列表 -->
<template>
  <div id="kt_post" class="post flex-column-fluid">
    <!--    顶部标题-->
    <TitleCard v-if="!params.wxShopId" style="margin: 10px auto; max-width: 97%" title="轮播/活动管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Banner Activity Management</li>
        </ul>
      </template>
    </TitleCard>

    <div
        id="kt_content_container"
        class="container"
        style="
        margin-top: 10px;
        max-width: 98%;
        flex-direction: row !important;
        display: flex;
      "
    >
      <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" class="" style="margin-bottom: 10px">
            <!-- {{idArr}} {{ sumMoney }} -->
            <div class="card card-flush" style="position: relative">
              <div class="card-body">
                <ul
                    class="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-semibold"
                >

                  <li
                      v-for="(item, i) in orderStatus"
                      v-if="!item.hidden"
                      :key="i"
                      :style="i==1||i==4||i==5?'':'margin-left: 30px'"
                      class="nav-item"
                  >
                    <el-badge
                        :hidden="
                        item.count != 0 &&
                        (item.title == '待付货款' ||
                          item.title == '待发货' ||
                          item.title == '售后'||item.title == '待开票')
                          ? false
                          : true
                      "
                        :max="99"
                        :value="item.count"
                        class="item"
                    >
                      <a
                          :ref="'page'+(i + 1)"
                          :class="index === i + 1 ? 'active' : ''"
                          :href="`#kt_tab_pane_${i + 1}`"
                          :style="
                          item.count != 0 &&
                          (item.title == '待付货款' ||
                            item.title == '待发货' ||
                            item.title == '售后')
                            ? `margin-right: 8px`
                            : ``
                        "
                          class="nav-link text-active-primary"
                          data-bs-toggle="tab"
                          style="
                          font-size: 13px;
                          font-weight: bold;
                          text-align: center
                        "
                          @click="jumpIndex(i + 1)"
                      >
                        <i :class="item.icon"></i>{{ item.title }}</a
                      >
                    </el-badge>
                  </li>
                </ul>
                <div style="position: absolute; top: 10px; right: 80px">
                  <el-button
                      size="small"
                      type="primary"
                      @click="createTopic(1)"
                  >创建活动
                  </el-button
                  >
                  <el-button
                      size="small"
                      type="primary"
                      @click="createTopic(2)"
                  >创建轮播
                  </el-button
                  >
                  <!-- @click="batchDelivery" -->
                  <!-- https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/finance/%E6%89%B9%E9%87%8F%E5%8F%91%E8%B4%A7%E6%A8%A1%E6%9D%BF.xlsx -->

                </div>
                <!--                <AllOrder
                                    ref="AllOrder"
                                    @reload="reload"
                                    @setIdArr="setIdArr"
                                    @setMoney="setMoney"
                                ></AllOrder>-->


                <div class="modal-body py-lg-10 px-lg-10">
                  <div id="topicTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                      <table
                          id="topicTable"
                          aria-describedby="topicTable_info" class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder no-footer dataTable" role="grid" style="width: 590px;">
                        <!--begin::Head-->
                        <thead class="fs-7 text-gray-400 text-uppercase">
                        <tr role="row">
                          <th class="sorting_disabled" colspan="1" rowspan="1">名称</th>
                          <th class="sorting_disabled" colspan="1" rowspan="1">图片</th>
                          <th class="sorting_disabled" colspan="1" rowspan="1">是否上架</th>
                          <th class="sorting_disabled" colspan="1" rowspan="1">创建时间</th>
                          <th class="sorting_disabled" colspan="1" rowspan="1">排序</th>
                          <th class="sorting_disabled" colspan="1" rowspan="1">操作</th>
                        </tr>
                        </thead>
                        <tbody id="topicTableBody" class="fs-6">

                        <tr v-for="(item,index) in topicList" :key="index" class="odd">
                          <td>{{ item.title }}</td>
                          <td><img :src="item.bannerUrl"
                                   style="width:190px;height:57px;object-fit: contain;"></td>
                          <td>
                            <el-switch
                              v-model="item.isShow"
                              active-color="#13ce66"
                              inactive-color="#999"
                              active-value="1"
                              inactive-value="2"
                              :disabled="true"
                          >
                          </el-switch>
                          </td>
                          <td>{{ item.crTime }}</td>
                          <td>{{ item.sortNum ? item.sortNum : '暂无' }}</td>
                          <td><a class="btn btn-info btn-sm px-4 mx-sm-1 my-sm-1"
                                 @click="editTopic(item.id)">&nbsp;编辑</a><a
                              class="btn btn-success btn-sm px-4 mx-sm-1 my-sm-1"
                              @click="editGoods(item.id)">&nbsp;商品维护</a><a
                              class="btn btn-danger btn-sm px-4 mx-sm-1 my-sm-1"
                              @click="deleteTopic(item.id)">&nbsp;删除</a></td>
                        </tr>
                        </tbody>

                      </table>
                      <div id="topicTable_processing" class="dataTables_processing" style="display: none;"></div>
                    </div>
                    <el-pagination :current-page="topicListQuery.pageIndex" :page-size="topicListQuery.pageSize"
                                   :page-sizes="[10,20,30]" :total="count"
                                   background
                                   layout="total, sizes, prev, pager, next, jumper" style="display:flex;justify-content:flex-end"
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange">
                    </el-pagination>
                  </div>
                </div>
                <!--end::Modal body-->
                <el-dialog v-if="saveTopicVisible" :visible.sync="saveTopicVisible" title="创建活动" width="40%"
                                           :before-close="handleSaveClose" >
                                  <template #title>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h2 v-if="saveShopMode == 1 && topicType==1">创建活动</h2>
                                        <h2 v-if="saveShopMode == 2 && index==1">编辑活动</h2>
                                        <h2 v-if="saveShopMode == 1 && topicType==2">创建轮播</h2>
                                        <h2 v-if="saveShopMode == 2 && index==2">编辑轮播</h2>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="modal-body" style="width: 70%;margin-left: 15%">
                                    <el-form :model="saveTopicForm" :rules="saveTopicFormRule" ref="saveTopicForm" label-position="top">
                                      <el-form-item prop="title">
                                            <span slot="label" style="display:inline-block;">
                                                <label class="form-label fw-bolder text-dark fs-6">名称</label>
                                            </span>
                                        <el-input v-model="saveTopicForm.title"></el-input>
                                      </el-form-item>
                                      <!--                                      <el-form-item prop="subTitle">
                                                                                    <span slot="label" style="display:inline-block;">
                                                                                        <label class="form-label fw-bolder text-dark fs-6">活动副标题</label>
                                                                                    </span>
                                                                              <el-input v-model="saveTopicForm.subTitle"></el-input>
                                                                            </el-form-item>-->
                                      <el-form-item prop="sortNum">
                                                <span slot="label" style="display:inline-block;">
                                                    <label class="form-label fw-bolder text-dark fs-6">排序</label>
                                                        <el-tooltip effect="dark" content="控制在商城中" placement="bottom">
                                                      <i class='el-icon-question'/>
                                                      </el-tooltip>
                                                </span>
                                        <el-input type="number" v-model="saveTopicForm.sortNum"></el-input>
                                      </el-form-item>
                                      <el-form-item prop="isShow">
                                                <span slot="label" style="display:inline-block;">
                                                    <label class="form-label fw-bolder text-dark fs-6">是否上架</label>
                                                </span>
                                              <el-switch
                                                  v-model="saveTopicForm.isShow"
                                                  active-color="#13ce66"
                                                  inactive-color="#999"
                                                  active-value="1"
                                                  inactive-value="2"
                                              >
                                              </el-switch>
                                      </el-form-item>
                                      <el-form-item label="首页logo(建议尺寸：190x57)" prop="bannerUrl">
                                            <span slot="label" style="display:inline-block;">
                                                <label
                                                    v-if="(saveShopMode == 1 && topicType==1) || (saveShopMode == 2 && index==1)"
                                                    class="form-label fw-bolder text-dark fs-6">活动图(建议尺寸：190x57)</label>
                                                <label
                                                    v-if="(saveShopMode == 1 && topicType==2) || (saveShopMode == 2 && index==2)"
                                                    class="form-label fw-bolder text-dark fs-6">轮播图(建议尺寸：340x140)</label>
                                            </span>
                                        <uploadImg moduleName="wxShopTopic" :imgUrl="saveTopicForm.bannerUrl" @upload-success="uploadSuccess"
                                                   @cancel-success="cancelSuccess"></uploadImg>
                                      </el-form-item>

                                    </el-form>
                                  </div>

                                  <div class="modal-footer">
                                    <button v-if="saveShopMode == 1" @click="submitAddTopic" type="button"
                                            class="btn btn-primary">提交</button>
                                    <button v-if="saveShopMode == 2" @click="submitUpdateTopic" type="button"
                                            class="btn btn-primary">保存</button>
                                  </div>
                </el-dialog>

                <!-- 商品的弹窗 -->
                <el-dialog v-if="wxTopicGoodsVisible" :visible.sync="wxTopicGoodsVisible" append-to-body=true title="商品管理"
                           width="90%">
                                  <template #title>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h2>商品管理</h2>
                                      </div>
                                    </div>
                                  </template>
                                  <topicGoodsModal :currentTopicId="currentTopicId"></topicGoodsModal>
                                </el-dialog>
              </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import topicGoodsModal from "./components/topicGoodsModal.vue"
import sd from "silly-datetime"
import {mapState} from "vuex";
import {
  addWxShopTopic,
  changeTopicStatus,
  getWxShopTopicInfo,
  getWxShopTopicList,
  updateWxShopTopic
} from "@/api/shopTopicApi";
import uploadImg from "./uploadImg.vue"

export default {
  name: "",
  components: {
    uploadImg, topicGoodsModal
  },
  computed: {
    ...mapState("user", ["role"]),
  },
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    currentWxShopId: {
      type: Number,
      default: null,
    },
    userTel: {
      type: String,
      default: "",
    }
  },
  // 组件的data属性
  data() {
    return {
      data: {
        successCount: 0,
        errorCount: 1,
      },
      columns: [
        {label: "订单编号", prop: "orderNo"},
        {label: "快递名字", prop: "expressName"},
        {label: "快递单号", prop: "expressNo"},
        {label: "字母单号(使用#)", prop: "otherExpressNo"},
        {label: "错误信息", prop: "errorMsg"},
      ],
      outcomeDialog: false,
      fileList: [],
      tableData: [],

      dialogVisible: false,
      index: 1,
      idArr: [],
      orderDateRange: [],
      orderStatus: [

        {
          title: "活动管理",
          count: 0,
          icon: "ki-outline ki-note-2 fs-4 me-2",
          hidden: false
        },
        {
          title: "轮播管理",
          count: 0,
          icon: "ki-outline ki-bookmark-2 fs-4 me-2",
          hidden: false
        },

      ],
      params: {
        pageIndex: 1,
        pageSize: 10,
        // 订单编号
        orderNo: "",
        // 订单状态
        orderStatus: "",
        // 开票状态
        invoiceState: "",
        // 订单日期
        startTime: "",
        endTime: "",
        // 下单人手机号
        telNum: this.userTel,
        // 商品来源
        fromType: "",
        wxShopId: this.currentWxShopId == null ? "" : this.currentWxShopId,
        // 收件人手机号
        tel: ""
      },
      orderStatusOptions: [
        {
          value: "1",
          label: "待付款",
        },
        {
          value: "2",
          label: "待发货",
        },
        {
          value: "3",
          label: "已发货",
        },
        {
          value: "4",
          label: "已完结",
        },
      ],
      invoiceStateOptions: [
        {
          value: "1",
          label: "已开票",
        },
        {
          value: "2",
          label: "未开票",
        },
      ],
      sourceOfGoodsOptions: [
        {
          value: "1",
          label: "自有商品",
        },
        {
          value: "4",
          label: "厂供商品",
        },
        {
          value: "3",
          label: "全国商品池",
        },
        {
          value: "2",
          label: "二级供应商",
        },
      ],
      //日期选择器 用
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      myCashOut: {
        money: "",
      },
      sumMoney: 0,

      topicListQuery: {
        pageIndex: 1,
        pageSize: 10,
        type: 1, //1：活动 2：轮播
      },
      topicList: [],
      currentTopic: {},
      currentTopicId: -1,

      count: 0,
      saveTopicVisible: false,
      saveShopMode: 1,
      topicType: 1, //1：活动 2：轮播 创建时

      saveTopicForm: {
        title: "",
        subTitle: "",
        sortNum: "",
        bannerUrl: "",
        type: "",
        isShow: "1",
      },
      saveTopicFormRule: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ]
      },

      wxTopicGoodsVisible: false




    };
  },
  // 组件的方法
  methods: {

    jumpIndex(index) {
      this.index = index;
      this.topicListQuery.type = this.index;
      this.getTopicList();
    },


    async getTopicList() {
      const {data} = await getWxShopTopicList(this.topicListQuery);
      this.topicList = data.rows
      this.count = data.count
    },

    createTopic(type) {
      this.saveTopicForm = {
        title: "",
        subTitle: "",
        sortNum: "",
        bannerUrl: "",
        type: "",
        isShow:"1",
      }
      this.saveShopMode = 1;
      this.saveTopicVisible = true;
      this.topicType = type;
      this.saveTopicForm.type = type;
    },

    handleSaveClose() {
      this.saveTopicVisible = false
      this.saveTopicForm.bannerUrl = ""
      this.saveTopicForm.title = ""
      this.saveTopicForm.sortNum = ""
      this.saveTopicForm.subTitle = ""
    },

    uploadSuccess(res) {
      console.log(res)
      this.saveTopicForm.bannerUrl = res.data.fileUrl
    },
    cancelSuccess(res) {
      if (res) {
        console.log(res)
        this.saveTopicForm.bannerUrl = ""
      }
    },

    handleCurrentChange(param) {
      this.topicListQuery.pageIndex = param
      this.getTopicList();
    },
    handleSizeChange(param) {
      this.topicListQuery.pageIndex = 1
      this.topicListQuery.pageSize = param
      this.getTopicList();
    },

    async submitAddTopic() {
      const res = await addWxShopTopic(this.saveTopicForm);
      console.log(res)
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '创建成功!'
        });
        this.saveTopicVisible = false

        await this.getTopicList()
      }
    },

    async editTopic(id) {
      this.saveTopicVisible = true
      const queryOne = {wxShopTopicId: id};
      const {data} = await getWxShopTopicInfo(queryOne);
      this.saveShopMode = 2
      this.currentTopic = data
      this.saveTopicForm.title = data.title
      this.saveTopicForm.bannerUrl = data.bannerUrl
      this.saveTopicForm.subTitle = data.subTitle
      this.saveTopicForm.sortNum = data.sortNum
      this.saveTopicForm.type = data.type
      this.saveTopicForm.isShow = data.isShow
    },

    async submitUpdateTopic() {
      this.currentTopic.title = this.saveTopicForm.title
      this.currentTopic.bannerUrl = this.saveTopicForm.bannerUrl
      this.currentTopic.subTitle = this.saveTopicForm.subTitle
      this.currentTopic.sortNum = this.saveTopicForm.sortNum
      this.currentTopic.type = this.saveTopicForm.type
      this.currentTopic.isShow = this.saveTopicForm.isShow
      const res = await updateWxShopTopic(this.currentTopic);
      this.$message({
        type: 'success',
        message: '编辑成功!'
      });
      this.saveTopicVisible = false
      await this.getTopicList()
    },

    editGoods(currentTopcId) {
      this.currentTopicId = currentTopcId
      this.wxTopicGoodsVisible = true
    },


    async deleteTopic(id) {
      const deleteParam = {
        id: id,
        status: 2
      };
      const res = await changeTopicStatus(deleteParam);
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        await this.getTopicList()
      }
    },



  },
  // 在created钩子中执行的操作
  created() {
    this.getTopicList()

  },
  filters: {
    dataFormat(date) {
      return sd.format(date, "YYYY-MM-DD HH:mm:ss");
    },
    format(num) {
      return (
          Number(num)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,") + " 元"
      );
    },
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
.el-range-separator {
  padding: 0;
}
</style>
